import { ImportReferenceStatus } from "api/generatedApi";
import { useTranslation } from "react-i18next";

export const useImportReferenceFormatter = () => {

  const { t } = useTranslation()
  const getStatusFormatted = (status: ImportReferenceStatus): string => {
    switch (status) {
      case "Approved":
        return t("admin.import.importStatuses.approved");
      case "Rejected":
        return t("admin.import.importStatuses.rejected");
      case "Created":
        return t("admin.import.importStatuses.created");
      case "ApprovedAndApplied":
        return t("admin.import.importStatuses.approvedAndApplied");
      case "ReadyForApproval":
        return t("admin.import.importStatuses.readyForApproval");
    }
  }

  return { getStatusFormatted };
}
