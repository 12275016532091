import { useTranslation } from "react-i18next";
import { SubmitHandler } from "react-hook-form";

import {
  ProjectSpecificOperationAndFavoriteResponse,
  GetProjectResponse,
  ProjectFolderResponse,
  usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsMaterialMutation,
  usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsOperationMutation,
  usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsProjectspecificoperationMutation,
} from "api/generatedApi";
import { CreateWorkItemDialog } from "components/page/folder/content/measurements/create/create-work-item-dialog/create-work-item";
import { useDialog } from "shared/dialog/use-dialog";
import { ResultDialogType, useMutation } from "utils/hooks/use-mutation";
import { UseMapWorkItem } from "../../use-map-work-item";
import { useWorkItemRestrictions } from "shared/user-restrictions/use-workitems-restrictions";
import { useToast } from "shared/toast/hooks/use-toast";
import { FormDataWorkItem } from "../create-work-item-dialog/create-work-item-form-data";
import { formatDateToRequestShortDate } from "utils/formats";

interface Prop {
  project: GetProjectResponse;
  folder: ProjectFolderResponse | undefined;
  favorites: ProjectSpecificOperationAndFavoriteResponse[];
}

export function useCreateWorkItem(props: Prop) {
  const { project, folder, favorites } = props;
  const { t } = useTranslation();
  const { canCreateWorkitem } = useWorkItemRestrictions(project);
  const toast = useToast();
  const [openCreateWorkItemDialog, closeCreateWorkItemDialog] = useDialog(CreateWorkItemDialog);
  const [createMaterialItem] = usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsMaterialMutation();
  const [createOperationItem] = usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsOperationMutation();
  const [createProjectSpecificOperationItem] = usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsProjectspecificoperationMutation();
  const { mapMountingCode, mapSupplementOperationsRequests, mapSupplementRequests } = UseMapWorkItem();

  const wrapMutation = useMutation({
    onSuccess: closeCreateWorkItemDialog,
    successProps: {
      description: t("content.measurements.create.success.description"),
    },
    resultDialogType: ResultDialogType.Toast,
  });

  const callCreateItem = (data: FormDataWorkItem) => {
    const projectId = project?.id ?? "";
    const folderId = folder?.projectFolderId ?? "";
    const workItemAmount = data.amount && data.amount > 0 ? data.amount : undefined;
    const supplements = mapSupplementRequests(data.supplements);
    const workItemDate = data.date ?? formatDateToRequestShortDate(new Date());

    switch (data.workitemType) {
      case "Material":
        return createMaterialItem({
          projectId,
          folderId,
          registerWorkItemMaterialRequest: {
            materialId: data.material.id,
            workItemAmount,
            workItemMountingCode: mapMountingCode(data.mountingCode),
            supplementOperations: mapSupplementOperationsRequests(data.supplementOperations),
            supplements,
            workItemDate,
          },
        });
      case "Operation":
        return createOperationItem({
          projectId,
          folderId,
          registerWorkItemOperationRequest: {
            operationId: data.operation.operationId,
            workItemAmount,
            supplements,
            workItemDate,
          },
        });
      case "ProjectSpecificOperation":
        return createProjectSpecificOperationItem({
          projectId,
          folderId,
          registerWorkItemProjectSpecificOperationRequest: {
            projectSpecificOperationId: data.projectSpecificOperation.projectSpecificOperationId,
            workItemAmount,
            supplements,
            workItemDate,
          },
        });
    }
  };

  const handleOnSubmit: SubmitHandler<FormDataWorkItem> = (data) => {
    const create = callCreateItem(data);
    if (create) {
      wrapMutation(create);
    }
  };

  const handleClick = () => {
    if (!canCreateWorkitem(folder)) {
      toast.error(t("content.measurements.create.restrictionError"));
      return;
    }
    openCreateWorkItemDialog({ onSubmit: handleOnSubmit, folder, project, favorites });
  };

  return handleClick;
}
