import { useTranslation } from "react-i18next";

import { GetProjectResponse, ProjectFolderResponse, usePutApiProjectsByProjectIdFoldersAndFolderIdMultiplicationFactorMutation } from "api/generatedApi";
import { useDialog } from "shared/dialog/use-dialog";
import { ResultDialogType, useMutation } from "utils/hooks/use-mutation";
import { SubmitHandler } from "react-hook-form";
import { MultiplyFolder, MultiplyFolderFormData } from "../multiply-folder";
import { useDispatch } from "react-redux";
import { api } from "api/enhancedEndpoints";

interface Props {
  project: GetProjectResponse;
  folder: ProjectFolderResponse;
}

export function useMultiplyFolder(props: Props) {
  const { project, folder } = props;
  const { t } = useTranslation();
  const [openMultiplyFolderDialog, closeMultiplyFolderDialog] = useDialog(MultiplyFolder);
  const [multiplyFolder] = usePutApiProjectsByProjectIdFoldersAndFolderIdMultiplicationFactorMutation();

  const dispatch = useDispatch();

  const onUpdateMultiplicationFactorSuccess = () => {
    dispatch(api.util.invalidateTags(["ThisProject", "DashboardSummary", "WorkItemsSummation", "Projects", "Folders"]));
    closeMultiplyFolderDialog();
  };

  const wrapMutation = useMutation({
    onSuccess: onUpdateMultiplicationFactorSuccess,
    successProps: {
      description: t("folder.multiply.successMultiply.description"),
    },
    errorProps: {
      description: t("folder.multiply.errorMultiply.description"),
    },
    resultDialogType: ResultDialogType.Toast,
  });

  const handleOnSubmit: SubmitHandler<MultiplyFolderFormData> = (data) => {
    if (!project || !folder) {
      return;
    }
    const projectId = project.id ?? "";
    const folderId = folder.projectFolderId ?? "";
    const multiplicationFactor = data.factor;
    wrapMutation(
      multiplyFolder({
        folderId,
        projectId,
        updateProjectFolderMultiplicationFactorRequest: {
          multiplicationFactor,
        },
      })
    );
  };

  const handleClick = () => {
    openMultiplyFolderDialog({ onSubmit: handleOnSubmit, folder });
  };

  return handleClick;
}
