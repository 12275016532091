import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { GetCompensationPaymentParticipantResponse } from "api/generatedApi";
import { useOrder } from "shared/table/use-order";
import { sortCompareNumber, sortCompareString } from "utils/compares";
import { CompensationPaymentParticipantSelectorStepTableRow } from "./participants-table-row";
import { TableHeaderConfig } from "shared/table/table-header-type";
import { TableHeader } from "shared/table/table-header";

interface Props {
  participants: GetCompensationPaymentParticipantResponse[];
  selectedParticipants: string[];
  onSelectedIds: (ids: string[]) => void;
}

export function CompensationPaymentParticipantsTable(props: Props) {
  type CompensationPaymentUsersSortableId = "Name" | "Amount";

  const { participants, selectedParticipants, onSelectedIds } = props;
  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set<string>(selectedParticipants));
  const { direction, orderBy, getLabelProps } = useOrder<CompensationPaymentUsersSortableId>("Name");

  const headerConfig: TableHeaderConfig<CompensationPaymentUsersSortableId>[] = [
    {
      id: "Name",
      title: t("common.name"),
      testid: "dashboard-compensation-payments-participant-table-header-name",
      alignment: "left",
      sortable: true,
    },
    {
      id: "Amount",
      title: t("dashboard.compensationPayments.participants.table.hoursAndAmount"),
      testid: "dashboard-compensation-payments-participant-table-header-hours-and-amount",
      alignment: "right",
      sortable: true,
    },
  ];

  const sortedData = useMemo(() => {
    var sortedList: GetCompensationPaymentParticipantResponse[] = [...participants];

    switch (orderBy) {
      case "Amount":
        sortedList = [...participants].sort((a, b) => sortCompareNumber(direction, a.payment, b.payment));
        break;
      case "Name":
        sortedList = [...participants].sort((a, b) => sortCompareString(direction, a.name, b.name));
        break;
    }
    return sortedList;
  }, [orderBy, participants, direction]);

  const onCheck = useCallback(
    (id: string | undefined) => {
      if (id === undefined) return;
      let ids = new Set(selectedIds);
      if (ids.has(id)) {
        ids.delete(id);
      } else {
        ids.add(id);
      }
      setSelectedIds(ids);
      onSelectedIds(Array.from(ids));
    },
    [onSelectedIds, selectedIds]
  );

  const onCheckAll = (checked: boolean) => {
    let ids = new Set<string>();
    if (checked) {
      participants?.forEach((participant) => ids.add(participant.projectAffiliateId ?? ""));
    }
    setSelectedIds(ids);
    onSelectedIds(Array.from(ids));
  };

  const isParticipantChecked = (id: string | undefined): boolean => {
    return selectedIds.has(id ?? "");
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHeader method="checkbox" headerConfig={headerConfig} getLabelProps={getLabelProps} checked={selectedIds.size === participants.length} checkBoxDisabled={false} onCheckAllProps={onCheckAll} />
        <TableBody>
          {sortedData.map((participant, index) => {
            return <CompensationPaymentParticipantSelectorStepTableRow key={index} row={participant} onCheck={onCheck} checked={isParticipantChecked(participant.projectAffiliateId)} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
