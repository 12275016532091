import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FieldError, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputContainer } from "../../../../../../../shared/styles/input-container-style";
import { CreateProjectUserFormData } from "./project-user-form-data";

import { CountryIso2, defaultCountries, FlagEmoji, parseCountry, usePhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

interface Props {
  register: UseFormRegister<CreateProjectUserFormData>;
  fieldError: FieldError | undefined;
  value?: string;
  onChange: (phone: string) => void;
}

export const ProjectUserPhoneNumber = (props: Props) => {
  const { register, fieldError, value, onChange } = props;
  const { t } = useTranslation();

  const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: "dk",
    value,
    countries: defaultCountries,
    onChange: (data) => {
      onChange(data.phone);
    },
  });

  return (
    <InputContainer>
      <Typography variant="caption" color={"primary.main"}>
        {t("dashboard.users.create.captionPhone")}
      </Typography>
      <TextField
        data-testid="create-project-users-phone"
        {...(register("phone"),
        {
          value: phone,
          onChange: handlePhoneValueChange,
        })}
        variant="filled"
        sx={{ width: "100%" }}
        label={t("dashboard.users.create.placeholderPhone")}
        type="tel"
        error={!!fieldError}
        helperText={fieldError?.message}
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginRight: "2px", marginLeft: "-8px" }}>
              <Select
                MenuProps={{
                  style: {
                    height: "300px",
                    width: "360px",
                    top: "10px",
                    left: "-34px",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                sx={{
                  width: "max-content",
                  fieldset: {
                    display: "none",
                  },
                  '&.Mui-focused:has(div[aria-expanded="false"])': {
                    fieldset: {
                      display: "block",
                    },
                  },
                  ".MuiSelect-select": {
                    padding: "8px",
                    paddingRight: "24px !important",
                  },
                  svg: {
                    right: 0,
                  },
                }}
                value={country}
                onChange={(e) => setCountry(e.target.value as CountryIso2)}
                renderValue={(value) => <FlagEmoji iso2={value} style={{ display: "flex" }} />}
              >
                {defaultCountries.map((c) => {
                  const country = parseCountry(c);
                  return (
                    <MenuItem key={country.iso2} value={country.iso2}>
                      <FlagEmoji iso2={country.iso2} style={{ marginRight: "8px" }} />
                      <Typography marginRight="8px">{country.name}</Typography>
                      <Typography color="gray">+{country.dialCode}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          ),
        }}
      />
    </InputContainer>
  );
};
