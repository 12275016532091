import { ImportReferenceResponse, usePutApiAdministrationImportreferencesMutation } from "api/generatedApi";
import { useDialog } from "shared/dialog/use-dialog";
import { ViewImportReferenceDetailDialog } from "../import-reference-detail-dialog";
import { SubmitHandler } from "react-hook-form";
import { ImportReferenceApprovalData } from "../../input/import-reference-approval-data";
import { ResultDialogType, useMutation } from "utils/hooks/use-mutation";
import { t } from "i18next";

interface Props {
  importReference: ImportReferenceResponse;
}

export function useViewImportReference(props: Props) {
  const { importReference } = props;

  const [openImportReferenceDialog, closeImportReferenceDialog] = useDialog(ViewImportReferenceDetailDialog);
  const [updateImportReferenceStatus] = usePutApiAdministrationImportreferencesMutation()
  const wrapMutation = useMutation({
    onSuccess: closeImportReferenceDialog,
    successProps: {
      description: t("admin.import.details.updateStatus.success.description")
    },
    errorProps: {
      description: t("admin.import.details.updateStatus.error.description")
    },
    resultDialogType: ResultDialogType.Toast
  });

  const handleOnSubmit: SubmitHandler<ImportReferenceApprovalData> = (data) => {
    wrapMutation(
      updateImportReferenceStatus({
        updateImportReferenceStatusRequest: {
          importReferenceId: data.importReferenceId,
          status: data.status
        }
      })
    )
  };

  const handleClick = () => {
    openImportReferenceDialog({ importReference, onSubmit: handleOnSubmit });
  }

  return handleClick;
}