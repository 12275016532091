import { Paper } from "@mui/material";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginImage from "assets/images/login.jpg";

export function Intercept() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/login`);
  }, []);

  return (
    <Paper
      sx={{
        backgroundImage: `url(${LoginImage})`,
        width: "100%",
        height: "100vh",
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: 0,
      }}
    >
      <Fragment />
    </Paper>
  );
}
