import { makeFullPath } from "api/path";
import { useDownloadFile } from "components/shared/download-file/use-download-file";
import { t } from "i18next";
import { useToast } from "shared/toast/hooks/use-toast";


export function useDownloadReport() {
  const toast = useToast();
  const downloadFile = useDownloadFile();

  const clickDownloadReport = (importReferenceId: string) => {
    try {
      const url = makeFullPath(`/api/administration/importreferences/${importReferenceId}/importreportasspreadsheet`);
      const filename = t("admin.import.report.filename", { importReferenceId: importReferenceId })
      downloadFile(url, filename);
    }
    catch {
      toast.error(t("admin.import.report.downloadError"));
    }
  }

  return clickDownloadReport;
}