import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import styled from "@mui/system/styled";
import { ProjectUserResponse } from "api/generatedApi";
import { TableHeaderConfig } from "shared/table/table-header-type";
import { TableHeader } from "shared/table/table-header";
import { useOrder } from "shared/table/use-order";

interface Props {
  users: ProjectUserResponse[];
}

export const PrintSectionProjectParticipants = ({ users }: Props) => {
  type ProjectUserSortableId = "Name" | "Role" | "Email" | "Addres" | "Phone";

  const { t } = useTranslation();
  const { getLabelProps } = useOrder<ProjectUserSortableId>("Name");

  const headerConfig: TableHeaderConfig<ProjectUserSortableId>[] = [
    {
      id: "Name",
      title: t("common.name"),
      sortable: false,
      testid: "print-section-project-participants-header-name",
      alignment: "left",
    },
    {
      id: "Role",
      title: t("dashboard.reports.printReports.projectUser.table.role"),
      sortable: false,
      testid: "print-section-project-participants-header-role",
      alignment: "left",
    },
    {
      id: "Email",
      title: t("dashboard.reports.printReports.projectUser.table.email"),
      sortable: false,
      testid: "print-section-project-participants-header-email",
      alignment: "left",
    },
    {
      id: "Addres",
      title: t("dashboard.reports.printReports.projectUser.table.adr"),
      sortable: false,
      testid: "print-section-project-participants-header-adr",
      alignment: "left",
    },
    {
      id: "Phone",
      title: t("dashboard.reports.printReports.projectUser.table.phone"),
      sortable: false,
      testid: "print-section-project-participants-header-phone",
      alignment: "left",
    },
  ];

  const PrintContainer = styled(Box)({
    "@media print": {
      breakInside: "avoid",
    },
  });

  return (
    <PrintContainer sx={{ display: "flex", flexDirection: "column", gap: 2, backgroundColor: "white", p: 5, borderRadius: 2 }}>
      <Typography variant="h6" sx={{ pb: 2 }}>
        {t("dashboard.reports.printReports.projectUser.title")}
      </Typography>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHeader headerConfig={headerConfig} getLabelProps={getLabelProps} />
          <TableBody>
            {users.map((user, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{t(`dashboard.reports.printReports.projectUser.roles.${user.role}`)}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.address}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </PrintContainer>
  );
};
