import { Typography, Box, List, ListItem, ListItemText, Grid } from "@mui/material";
import { StyledAdminContent } from "../admin.styled";
import { useTranslation } from "react-i18next";
import { useGetApiAdministrationMetricsQuery, useGetApiAdministrationProjectsInformationQuery, useGetApiAdministrationUsersInformationQuery } from "api/generatedApi";
import ServerMetricsTable from "./server-metrics/server-metrics-table";

export const AdminMonitoring = () => {
  const { t } = useTranslation();

  const getUsersInformation = useGetApiAdministrationUsersInformationQuery();
  const getProjectsInformation = useGetApiAdministrationProjectsInformationQuery();

  return (
    <Box>
      <Grid container item spacing={2} pb={4}>
        <Grid item xs={12} lg={6} xl={6} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h5">{t("admin.monitoring.usersInformation.title")}</Typography>
          <List>
            <ListItem>
              <ListItemText>
                {t("admin.monitoring.usersInformation.numberOfUsers")}: {getUsersInformation.data?.numberOfUsers ?? 0}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t("admin.monitoring.usersInformation.numberOfProjectOwners")}: {getUsersInformation.data?.numberOfProjectOwners ?? 0}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t("admin.monitoring.usersInformation.numberOfProjectManagers")}: {getUsersInformation.data?.numberOfProjectManagers ?? 0}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t("admin.monitoring.usersInformation.numberOfParticipants")}: {getUsersInformation.data?.numberOfParticipants ?? 0}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t("admin.monitoring.usersInformation.numberOfInactiveParticipants")}: {getUsersInformation.data?.numberOfInactiveParticipants ?? 0}
              </ListItemText>
            </ListItem>
          </List>
        </Grid>

        <Grid item xs={12} lg={6} xl={6} sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h5">{t("admin.monitoring.projectsInformation.title")}</Typography>
          <List>
            <ListItem>
              <ListItemText>
                {t("admin.monitoring.projectsInformation.numberOfProjects")}: {getProjectsInformation.data?.totalNumberOfProjects}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t("admin.monitoring.projectsInformation.numberOfTwelveOneAProjects")}: {getProjectsInformation.data?.numberOfTwelveOneAProjects}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t("admin.monitoring.projectsInformation.numberOfTwelveOneBProjects")}: {getProjectsInformation.data?.numberOfTwelveOneBProjects}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t("admin.monitoring.projectsInformation.numberOfTwelveOneCProjects")}: {getProjectsInformation.data?.numberOfTwelveOneCProjects}
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                {t("admin.monitoring.projectsInformation.numberOfTwelveTwoProjects")}: {getProjectsInformation.data?.numberOfTwelveTwoProjects}
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h5">{t("admin.monitoring.metrics.title")}</Typography>
      </Box>
      <ServerMetricsTable />
    </Box>
  );
};
