
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { UseFormRegister } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { InputContainer } from "shared/styles/input-container-style";
import { ExtraWorkAgreementFormData } from "../hooks/use-input-validation";
import { ExtraWorkAgreementResponse } from "api/generatedApi";

interface Props {
  register: UseFormRegister<ExtraWorkAgreementFormData>;
  agreement?: ExtraWorkAgreementResponse;
  disabled?: boolean;
}

export const EditExtraWorkAgreementIsPrivate = (props: Props) => {
  const { agreement, register, disabled } = props;
  const { t } = useTranslation();

  return (
    <>
      <InputContainer>
        <FormControlLabel
          data-testid="create-extra-work-agreement-is-private"
          control={<Checkbox checked={agreement?.isPrivate} {...register("isPrivate")} disabled={disabled} />}
          label={<>
            {t("dashboard.extraWorkAgreements.create.isPrivate.label")}
          </>} />
      </InputContainer>
      <Typography variant={"caption"} >
        {t("dashboard.extraWorkAgreements.create.isPrivate.description")}
      </Typography>
    </>
  );
}