import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import styled from "@mui/system/styled";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import IconButton from "@mui/material/IconButton";
import { Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";

import { GetProjectResponse, WorkItemResponse } from "api/generatedApi";
import { MobileWorkItemMaterialTableRow } from "./mobile-work-item-material-row";
import { MobileWorkItemOperationTableRow } from "./mobile-work-item-operation-row";
import { ExtendedProjectFolder } from "pages/project/hooks/use-map-tree-to-flat";
import { FolderLockedButton } from "components/page/folder/lock-folder/folder-locked-button";
import { TextIcon } from "components/shared/text-icon/text-icon";
import { useWorkItemRestrictions } from "shared/user-restrictions/use-workitems-restrictions";
import { useDeleteWorkitems } from "../../hooks/use-delete-workitem";
import { useMoveWorkitems } from "../../move/hooks/use-move";
import { useCopyWorkItems } from "../../copy/hooks/use-copy";
import { TableHeader } from "shared/table/table-header";
import { TableHeaderConfig } from "shared/table/table-header-type";
import { MultiplyFolderButton } from "components/page/folder/multiply-folder/multiply-folder-button";
import { useFeatures } from "utils/hooks/use-features";

type WorkItemSortableId = "workItem" | "amount" | "payment";

interface Prop {
  project: GetProjectResponse;
  selectedFolder: ExtendedProjectFolder;
  workItems?: WorkItemResponse[];
  dataFlatlist: ExtendedProjectFolder[];
  refetch: () => void;
}

export const MobileFolderWorkItems = (props: Prop) => {
  const { selectedFolder, workItems, project, dataFlatlist, refetch } = props;
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatures();
  const { canSelectWorkItem, canDeleteWorkitems, canMoveWorkitems, canCopyWorkitems } = useWorkItemRestrictions(project);
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set<string>());
  const [checkedAll, setCheckedAll] = useState(false);
  const openDeleteMeasurementDialog = useDeleteWorkitems({ project, folder: selectedFolder, selectedIds });
  const moveWorkitemsDialog = useMoveWorkitems({ project, folder: selectedFolder, dataFlatlist, selectedWorkitemIds: selectedIds });
  const copyWorkItemsDialog = useCopyWorkItems({ project, dataFlatlist, selectedFolder, selectedIds });

  const onCheck = useCallback(
    (id: string | undefined) => {
      if (id === undefined) return;
      let ids = new Set(selectedIds);
      if (ids.has(id)) {
        ids.delete(id);
      } else {
        ids.add(id);
      }
      setSelectedIds(ids);
    },
    [selectedIds]
  );

  const onCheckAll = (checked: boolean) => {
    let ids = new Set<string>();
    if (checked) {
      workItems?.forEach((item) => ids.add(item.workItemId ?? ""));
    }
    setSelectedIds(ids);
    setCheckedAll(!checkedAll);
  };

  const isWorkItemChecked = (id: string | undefined): boolean => {
    return selectedIds.has(id ?? "");
  };

  const deleteSelectedItems = () => {
    // TODO: call confirmation dialog
    // When confirm button is tapped in confirmation dialog:
    // when success, invalidate folder and clear selected ids
    // if failure, give information dialog to user
    openDeleteMeasurementDialog();
  };

  const getWorkItemRow = (workItem: WorkItemResponse) => {
    switch (workItem.workItemType) {
      case "Material":
        return <MobileWorkItemMaterialTableRow key={workItem.workItemId} project={project} folder={selectedFolder} workItem={workItem} onCheck={onCheck} checked={isWorkItemChecked(workItem.workItemId)} />;
      case "Operation":
      case "ProjectSpecificOperation":
        return <MobileWorkItemOperationTableRow key={workItem.workItemId} project={project} folder={selectedFolder} workItem={workItem} onCheck={onCheck} checked={isWorkItemChecked(workItem.workItemId)} />;
    }
  };

  const NoWorkItems = styled(Box)`
    width: 100%;
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
  `;

  const headerConfig: TableHeaderConfig<WorkItemSortableId>[] = [
    {
      id: "workItem",
      title: t("content.measurements.table.workItemSmall"),
      sortable: false,
      testid: "measurements-table-header-sorting-work-item",
      alignment: "left",
    },
    {
      id: "amount",
      title: t("content.measurements.table.amount"),
      sortable: false,
      testid: "measurements-table-header-sorting-amount",
      alignment: "center",
    },
    {
      id: "payment",
      title: t("content.measurements.table.payment"),
      sortable: false,
      testid: "content.measurements.table.payment",
      alignment: "right",
    },
  ];

  return (
    <Box sx={{ flex: 1, flexDirection: "column", overflowY: "hidden", display: "flex", width: "100%", height: "100%" }}>
      {workItems && workItems.length > 0 && (
        <Fragment>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 0.5, gap: 0.5 }}>
            {selectedFolder && (
              <>
                <FolderLockedButton project={project} folder={selectedFolder} showText={true} />
                {isFeatureEnabled("FolderMultiplication") && (<MultiplyFolderButton project={project} folder={selectedFolder} hideText />)}
              </>
            )}
            <TextIcon>
              <IconButton onClick={refetch}>
                <RefreshIcon />
              </IconButton>
            </TextIcon>
            <TextIcon>
              <IconButton disabled={!canMoveWorkitems(selectedFolder, selectedIds)} onClick={moveWorkitemsDialog}>
                <DriveFileMoveOutlinedIcon />
              </IconButton>
            </TextIcon>
            <TextIcon>
              <IconButton disabled={!canCopyWorkitems(selectedFolder, selectedIds)} onClick={copyWorkItemsDialog}>
                <ContentCopyOutlinedIcon />
              </IconButton>
            </TextIcon>
            <TextIcon>
              <IconButton data-testid="mobile-measurement-table-delete-row" disabled={!canDeleteWorkitems(selectedFolder, selectedIds)} onClick={deleteSelectedItems}>
                <DeleteOutlinedIcon />
              </IconButton>
            </TextIcon>
          </Box>

          <TableContainer sx={{ height: "100%", overflowY: "auto", pb: "70px" }} component={Paper}>
            <Table sx={{ tableLayout: "fixed" }}>
              <TableHeader method="checkbox" narrowPadding={true} headerConfig={headerConfig} checked={selectedIds.size === workItems.length} checkBoxDisabled={!canSelectWorkItem(selectedFolder)} onCheckAllProps={onCheckAll} />
              <TableBody>
                {workItems.map((workItem) => {
                  return getWorkItemRow(workItem);
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      )}
      {workItems && workItems.length <= 0 && (
        <Fragment>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 0.5 }}>
            {selectedFolder && (
              <>
                <FolderLockedButton project={project} folder={selectedFolder} showText={true} />
                {isFeatureEnabled("FolderMultiplication") && (<MultiplyFolderButton project={project} folder={selectedFolder} hideText />)}
              </>
            )}
          </Box>
          <NoWorkItems color={"grey.100"}>{t("content.measurements.noWorkItemsIsRegistred")}</NoWorkItems>
        </Fragment>
      )}
    </Box>
  );
};
