import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import TableSortLabel from "@mui/material/TableSortLabel";
import styled from "@mui/system/styled";
import { TableHeaderConfig } from "./table-header-type";
import { costumPalette } from "theme/palette";

const TableCellStyled = styled(TableCell)<{ alignment?: "left" | "right" | "center"; cellwidth?: string; cellPaddingRight?: number }>(({ alignment, cellwidth, cellPaddingRight }) => ({
  color: costumPalette.primaryMain,
  backgroundColor: costumPalette.primaryLight,
  textAlign: alignment,
  width: cellwidth ?? "auto",
  paddingRight: cellPaddingRight ?? "auto",
}));

type Props<T> = {
  headerConfig: TableHeaderConfig<T>[];
  getLabelProps?: any;
  narrowPadding?: boolean;
} & (
  | {
      // Default state so method does not need to be defined
      method?: never;
    }
  | {
      method: "checkbox";
      checked: boolean;
      checkBoxDisabled: boolean;
      onCheckAllProps: (checked: boolean) => void;
    }
);

export const TableHeader = <T,>(props: Props<T>) => {
  const { method, headerConfig, getLabelProps, narrowPadding } = props;

  return (
    <TableHead sx={{ backgroundColor: "primary.light" }}>
      <TableRow>
        {method === "checkbox" && (
          <TableCell sx={{ backgroundColor: "primary.light", width: narrowPadding ? "40px" : 10, p: narrowPadding ? 0 : "default" }}>
            <Checkbox data-testid="dashboard-favorites-table-checkbox-all" onChange={(event) => props.onCheckAllProps(event.target.checked)} checked={props.checked} disabled={props.checkBoxDisabled} />
          </TableCell>
        )}
        {headerConfig.map((cell, index) => {
          if (cell.sortable) {
            return (
              <TableCellStyled data-testid={cell.testid} key={index} alignment={cell.alignment} cellwidth={cell.width}>
                <TableSortLabel {...getLabelProps(cell.id)}>{cell.title}</TableSortLabel>
              </TableCellStyled>
            );
          }
          return (
            <TableCellStyled data-testid={cell.testid} key={index} alignment={cell.alignment} cellwidth={cell.width}>
              {cell.title}
            </TableCellStyled>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
