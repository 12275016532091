import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Header } from "components/shared/header/header";
import { MobileBottomNavigation } from "components/shared/mobile-bottom-navigation/mobile-bottom-navigation";
import { Outlet, useLocation } from "react-router-dom";
import { ErrorBoundary } from "shared/error-boundary";
import usePwaUpdateStatus from "./hooks/use-pwa-update-status";
import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";
import { costumPalette } from "theme/palette";
import { useTranslation } from "react-i18next";

export const MobileLayout = () => {
  const location = useLocation();
  const { showUpdateBanner } = usePwaUpdateStatus();
  const { t } = useTranslation();

  return (
    // https://blog.logrocket.com/improving-mobile-design-latest-css-viewport-units/
    <Box sx={{ display: "flex", flexDirection: "column", height: "100dvh", overflow: "hidden" }}>
      <Header />
      {showUpdateBanner && (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="100%" height="80px" sx={{ backgroundColor: costumPalette.green }}>
          <InfoIcon sx={{ color: "white", fontSize: "40px" }} />
          <Box ml="8px" width="270px">
            <Typography variant="subtitle1" color="white">
              {t("pwa.updateMessageMobile")}
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ flex: "1", display: "flex", overflowY: "auto" }}>
        <Box sx={{ width: "100%", height: "100%", padding: 0, paddingBottom: 5, overflow: "hidden" }}>
          <Grid container direction="column" height="100%">
            <ErrorBoundary key={location.pathname}>
              <Outlet />
            </ErrorBoundary>
          </Grid>
          <MobileBottomNavigation />
        </Box>
      </Box>
    </Box>
  );
};
