import { useMsal } from "@azure/msal-react";
import { api } from "api/enhancedEndpoints";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { saveInvitationRedirectUri } from "utils/storage/storage";

type ValidateInviteStatus = "success" | "expired" | "invalid" | "awaiting";

export const useLandingHook = () => {
  const [validateInvitation] = api.endpoints.getApiProjectsByProjectIdInvitationsAndInvitationId.useLazyQuery();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [validationStatus, setValidationStatus] = useState<ValidateInviteStatus>("awaiting");

  const validate = useCallback(async () => {
    try {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const projectId = urlParams.get("projectId");
      const invitateId = urlParams.get("inviteId");
      const value = urlParams.get("value")?.replace("projects", "");

      const response = await validateInvitation({
        projectId: projectId ?? "",
        invitationId: invitateId ?? "",
        value: value ?? "",
      }).unwrap();

      if (response === "success") {
        navigate(`/projects/${projectId}`);
      }

      if (response === "expired") {
        setValidationStatus("expired");
      }

      if (response === "invalid") {
        setValidationStatus("invalid");
      }
    } catch (error) {
      // unexpected status was returned..
      setValidationStatus("invalid");
    }
  }, [validateInvitation, navigate]);

  const handleLogin = useCallback(() => {
    let uri = saveInvitationRedirectUri();
    navigate(`/login?redirect_uri=${uri}`);
  }, [navigate]);

  const handleRedirect = useCallback(() => {
    const account = instance.getActiveAccount();
    if (!account) {
      // Not logged in => redirect to login
      setTimeout(() => {
        handleLogin();
      }, 1000);
    } else {
      // logged in => validate invite
      validate();
    }
  }, [instance, handleLogin, validate]);

  return { handleRedirect, validationStatus };
};
