import { GetProjectResponse } from "api/generatedApi";
import { formatTimestamp } from "utils/formats";

export function useProjectCalculation(project?: GetProjectResponse) {
  const projectHasCalculation = () => {
    //project doesnt have a calculation during creation
    if (!project) return false;

    if (project.calculation?.fileName && project.calculation?.importTime) {
      return true;
    }

    return false;
  };

  const calculationImportTime = () => {
    const dateString = project?.calculation?.importTime;
    return formatTimestamp(dateString, true);
  };

  const calculationFilename = () => {
    return project?.calculation?.fileName ?? "";
  };

  return { projectHasCalculation, calculationImportTime, calculationFilename };
}
