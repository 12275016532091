import { useCallback, useEffect, useState } from "react";
type PwaUpdateStatus = "newUpdateAvailable" | "upToDate" | null;

const usePwaUpdateStatus = () => {
  const [showUpdateBanner, setShowUpdateBanner] = useState(false);
  const hour = 1000 * 30;

  const refreshPwaUpdateStatus = useCallback(() => {
    const status: PwaUpdateStatus = localStorage.getItem("X_new_pwa_update") as PwaUpdateStatus;
    if (!status) {
      setShowUpdateBanner(false);
    } else {
      setShowUpdateBanner(status === "newUpdateAvailable");
    }
  }, []);

  useEffect(() => {
    refreshPwaUpdateStatus();
    const timer = setInterval(() => {
      refreshPwaUpdateStatus();
    }, hour);
    return () => {
      clearInterval(timer);
    };
  }, [refreshPwaUpdateStatus, hour]);

  return { showUpdateBanner };
};

export default usePwaUpdateStatus;
