import { useEffect } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {
  usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsMaterialPreviewMutation,
  usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsOperationPreviewMutation,
  usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsProjectspecificoperationPreviewMutation,
  WorkItemMaterialResponse,
  WorkItemOperationResponse,
  WorkItemType,
} from "api/generatedApi";
import { useToast } from "shared/toast/hooks/use-toast";
import { UseMapWorkItem } from "../../../use-map-work-item";
import { FormDataWorkItem } from "../create-work-item-form-data";

export interface Props {
  getValue: UseFormGetValues<FormDataWorkItem>;
  setValue: UseFormSetValue<FormDataWorkItem>;
  projectId: string;
  folderId: string;
  onPreviewCalculatedProps: () => void;
  workItemType: WorkItemType;
}

export function CalculationForPreviewStep(props: Props) {
  const { onPreviewCalculatedProps, getValue, setValue, projectId, folderId, workItemType } = props;
  const { t } = useTranslation();
  const toast = useToast();
  const { mapSupplementOperations, mapSupplementResponses, mapSupplementsToPreviewRequests, mapSupplementOperationsToPreviewRequests, mapMountingCode, mapMountingText } = UseMapWorkItem();
  const [getMaterialPreview] = usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsMaterialPreviewMutation();
  const [getOperationPreview] = usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsOperationPreviewMutation();
  const [getProjectSpecificOperationPreview] = usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsProjectspecificoperationPreviewMutation();

  useEffect(() => {
    switch (workItemType) {
      case "Material":
        setMaterialPreview();
        break;
      case "Operation":
        setOperationPreview();
        break;
      case "ProjectSpecificOperation":
        setProjectSpecificOperationPreview();
        break;
    }
  }, []);

  const setMaterialPreview = async () => {
    const workItemAmount = getValue("amount") ?? 0;
    const supplementOperations = mapSupplementOperations(getValue("supplementOperations"));
    const supplementOperationsForPreview = mapSupplementOperationsToPreviewRequests(getValue("supplementOperations"));
      const supplements = mapSupplementResponses(getValue("supplements"));

    const workItemText = getValue("material.name") ?? "Ukendt materiale";
    const mountingCode = mapMountingCode(getValue("mountingCode"));
    const mountingText = mapMountingText(getValue("mountingCode"));
    const workItemEanNumber = getValue("material.eanNumber") ?? "Ukendt EAN nummer";
    const workItemMaterialId = getValue("material.id") ?? "Ukendt id";
    const workItemMaterial: WorkItemMaterialResponse = {
      workItemEanNumber: workItemEanNumber,
      workItemMountingCode: mountingCode,
      workItemMountingCodeText: mountingText,
      supplementOperations: supplementOperations,
    };
    const workItemDate = getValue("date");

    const workItemType = "Material";

    const workItemId = getValue("workItem.workItemId");

    await getMaterialPreview({
      projectId: projectId,
      folderId: folderId,
      getWorkItemMaterialPreviewRequest: {
        materialId: workItemMaterialId,
        workItemAmount: workItemAmount,
        workItemMountingCode: mountingCode,
        supplementOperations: supplementOperationsForPreview,
        supplements: supplements,
        workItemDate,
        workItemId
      },
    })
      .unwrap()
      .then((response) => {
        const workItemTotalOperationTimeMilliseconds = response.totalWorkTimeMilliseconds;
        const workItemOperationTimeMilliseconds = response.operationTimeMilliseconds;
        const workItemTotalPaymentDkr = response.workItemTotalPaymentDkr;
        const createdInteractionResponse = {
          userName: response.createdBy,
          timestamp: response.createdTimestamp,
        };
        const lastModifiedInteractionResponse = {
          userName: response.updatedBy,
          timestamp: response.updatedTimestamp,
        };
        const workItemDate = response.workItemDate;

        setValue("workItem", {
          workItemType,
          workItemText,
          workItemAmount,
          supplements,
          workItemMaterial,
          workItemTotalOperationTimeMilliseconds,
          workItemOperationTimeMilliseconds,
          workItemTotalPaymentDkr,
          createdInteractionResponse,
          lastModifiedInteractionResponse,
          workItemDate,
          workItemId,
        });

        onPreviewCalculatedProps();
      })
      .catch((error) => {
        console.error(error);
        toast.error(t("content.measurements.create.calculatePreview.calculatePreviewMaterialError"));
      });
  };

  const setOperationPreview = async () => {
    const workItemAmount = getValue("amount") ?? 0;
    const supplementsForPreview = mapSupplementsToPreviewRequests(getValue("supplements"));
    const supplements = mapSupplementResponses(getValue("supplements"));

    const workItemText = getValue("operation.operationText") ?? "Ukendt materiale";
    const workItemOperationId = getValue("operation.operationId") ?? "Ukendt operationsid";
    const workItemOperation: WorkItemOperationResponse = {
      operationNumber: getValue("operation.operationNumber") ?? "Ukendt operationsnummer",
    };
    const workItemDate = getValue("date");

    const workItemType = "Operation";

    const workItemId = getValue("workItem.workItemId");

    await getOperationPreview({
      projectId: projectId,
      folderId: folderId,
      getWorkItemOperationPreviewRequest: {
        operationId: workItemOperationId,
        workItemAmount: workItemAmount,
        supplements: supplementsForPreview,
        workItemDate,
        workItemId
      },
    })
      .unwrap()
      .then((response) => {
        const workItemTotalOperationTimeMilliseconds = response.totalWorkTimeMilliseconds;
        const workItemOperationTimeMilliseconds = response.operationTimeMilliseconds;
        const workItemTotalPaymentDkr = response.workItemTotalPaymentDkr;
        const createdInteractionResponse = {
          userName: response.createdBy,
          timestamp: response.createdTimestamp,
        };
        const lastModifiedInteractionResponse = {
          userName: response.updatedBy,
          timestamp: response.updatedTimestamp,
        };
        const workItemDate = response.workItemDate;

        setValue("workItem", {
          workItemType,
          workItemText,
          workItemAmount,
          supplements,
          workItemOperation,
          workItemTotalOperationTimeMilliseconds,
          workItemOperationTimeMilliseconds,
          workItemTotalPaymentDkr,
          createdInteractionResponse,
          lastModifiedInteractionResponse,
          workItemDate,
          workItemId,
        });

        onPreviewCalculatedProps();
      })
      .catch((error) => {
        console.error(error);
        toast.error(t("content.measurements.create.calculatePreview.calculatePreviewOperationError"));
      });
  };

  const setProjectSpecificOperationPreview = async () => {
    const workItemAmount = getValue("amount") ?? 0;
    const supplementsForPreview = mapSupplementsToPreviewRequests(getValue("supplements"));
    const supplements = mapSupplementResponses(getValue("supplements"));

    const workItemText = getValue("projectSpecificOperation.projectSpecificOperationText") ?? "Ukendt materiale";
    const workItemOperationId = getValue("projectSpecificOperation.projectSpecificOperationId") ?? "Ukendt operationsid";
    const workItemOperation: WorkItemOperationResponse = {
      operationNumber: getValue("operation.operationNumber") ?? "Ukendt operationsnummer",
    };
    const workItemDate = getValue("date");

    const workItemType = "ProjectSpecificOperation";

    const workItemId = getValue("workItem.workItemId");

    await getProjectSpecificOperationPreview({
      projectId: projectId,
      folderId: folderId,
      getWorkItemProjectSpecificOperationPreviewRequest: {
        projectSpecificOperationId: workItemOperationId,
        workItemAmount: workItemAmount,
        supplements: supplementsForPreview,
        workItemDate,
        workItemId
      },
    })
      .unwrap()
      .then((response) => {
        const workItemTotalOperationTimeMilliseconds = response.totalWorkTimeMilliseconds;
        const workItemOperationTimeMilliseconds = response.operationTimeMilliseconds;
        const workItemTotalPaymentDkr = response.workItemTotalPaymentDkr;
        const createdInteractionResponse = {
          userName: response.createdBy,
          timestamp: response.createdTimestamp,
        };
        const lastModifiedInteractionResponse = {
          userName: response.updatedBy,
          timestamp: response.updatedTimestamp,
        };
        const workItemDate = response.workItemDate;

        setValue("workItem", {
          workItemType,
          workItemText,
          workItemAmount,
          supplements,
          workItemOperation,
          workItemTotalOperationTimeMilliseconds,
          workItemOperationTimeMilliseconds,
          workItemTotalPaymentDkr,
          createdInteractionResponse,
          lastModifiedInteractionResponse,
          workItemDate,
          workItemId,
        });

        onPreviewCalculatedProps();
      })
      .catch((error) => {
        console.error(error);
        toast.error(t("content.measurements.create.calculatePreview.calculatePreviewProjectSpecificOperationError"));
      });
  };

  return (
    <Grid container sx={{ padding: "20px" }}>
      <Grid item xs={12} sx={{ pt: 5, pb: 5, textAlign: "center" }}>
        <CircularProgress size={100} />
      </Grid>
    </Grid>
  );
}
