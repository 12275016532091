import { api } from "api/enhancedEndpoints";
import { GetProjectResponse } from "api/generatedApi";
import { DraftWorkItemRequest } from "./use-map-drafts";

export function UseDraftsCreateWorkItems() {
  const [createMaterial] = api.usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsMaterialMutation();
  const [createOperation] = api.usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsOperationMutation();
  const [createProjectSpecificOperation] = api.usePostApiProjectsByProjectIdFoldersAndFolderIdWorkitemsProjectspecificoperationMutation();

  const getCreatePostApiMutation = (projectId: string, folderId: string, request: DraftWorkItemRequest) => {
    switch (request.workItemType) {
      case "Material":
        return createMaterial({ projectId, folderId, registerWorkItemMaterialRequest: request.request });
      case "Operation":
        return createOperation({ projectId, folderId, registerWorkItemOperationRequest: request.request });
      case "ProjectSpecificOperation":
        return createProjectSpecificOperation({ projectId, folderId, registerWorkItemProjectSpecificOperationRequest: request.request });
    }
  };

  const createWorkItems = async (project: GetProjectResponse, folderId: string, requests: DraftWorkItemRequest[]) => {
    if (!project?.id) {
      return;
    }
    for (const request of requests) {
      try {
        await getCreatePostApiMutation(project.id, folderId, request)
          .unwrap()
          .then(() => {
            request.requestSuccess = true;
          })
          .catch((error) => {
            console.error(error);
            request.requestFailed = true;
          });
      } catch (error) {
        console.error(error);
        request.requestFailed = true;
      }
    }
  };

  return { createWorkItems };
}
