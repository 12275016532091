import { ProjectFolderResponse } from "api/generatedApi";
import { ExtendedProjectFolder } from "pages/project/hooks/use-map-tree-to-flat";

export function UseTreeFolderCounter() {
  const maxLevels = 25;

  const getParentsFoldersCount = (folder: ExtendedProjectFolder) => {
    let count = 0;
    let parent = folder;

    while (parent?.parent) {
      count++;
      parent = parent.parent;
    }

    return count;
  };

  const getChildrenSubFolderCount = (folder: ProjectFolderResponse): number => {
    if (!!folder.subFolders && folder.subFolders.length > 0) {
      const depths = folder.subFolders.map(getChildrenSubFolderCount);

      return 1 + Math.max.apply(Math, depths);
    }
    return 1;
  };

  const validateAppendFolderIntoFolder = (selected: ExtendedProjectFolder, moveTo: ExtendedProjectFolder): boolean => {
    const parentsLevels = getParentsFoldersCount(moveTo);
    const childrenLevels = getChildrenSubFolderCount(selected);

    return parentsLevels + childrenLevels > maxLevels;
  };

  const validateCreateFolder = (selected?: ExtendedProjectFolder): boolean => {
    if (!selected) {
      return false;
    }
    const parentsLevels = getParentsFoldersCount(selected);
    return parentsLevels + 1 < maxLevels;
  };

  return { validateAppendFolderIntoFolder, validateCreateFolder };
}
