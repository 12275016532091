import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import CircularProgress from "@mui/material/CircularProgress";

import { api } from "api/enhancedEndpoints";
import { GetProjectLogBookWeekQueryResponse, GetProjectResponse, useGetApiProjectsByProjectIdLogbookQuery } from "api/generatedApi";
import { formatTimestamp, formatTimestampToDate } from "utils/formats";
import { useLogbookCloseDialog } from "./close/hooks/use-close-dialog";
import { useLogbookUnlock } from "./unlock/hooks/use-unlock";
import { useToast } from "shared/toast/hooks/use-toast";
import { useLogbookRestrictions } from "shared/user-restrictions/use-logbook-restrictions";
import { ProjectLogbookNotes } from "./notes/logbook-notes";
import { ProjectLogbookStats } from "./logbook-stats/logbook-stats";
import { ProjectLogbookSelectUser } from "./select-user/logbook-select-user";
import { ProjectLogbookSelectPeriod } from "./select-period/logbook-select-period";
import { ProjectLogbookTimeRegistration } from "./time-registration/time-registration";

export interface SelectedLogBookUser {
  logBookUserId: string;
  affiliateId: string;
}

interface Props {
  project: GetProjectResponse;
}

export const ProjectLogbook = (props: Props) => {
  const { project } = props;
  const { t } = useTranslation();
  const tRef = useRef(t);
  const toast = useToast();
  const toastRef = useRef(toast);
  const { canViewLogbook, canCloseLogbookPeriod, canOpenLogbookPeriod } = useLogbookRestrictions(project);
  const activeProjectAffiliateId = project.currentUserAffiliateId;
  const [logbookWeekData, setlogbookWeekData] = useState<GetProjectLogBookWeekQueryResponse | undefined>(undefined);

  const [dateRange, setDateRange] = useState("");
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const { data: getLogbookUsersData, isError: getLogbookUsersDataError } = useGetApiProjectsByProjectIdLogbookQuery({
    projectId: project.id ? project.id : "",
  });
  const [selectedLogBookUser, setSelectedLogBookUser] = useState<SelectedLogBookUser | undefined>(undefined);
  const [queryLogbookByDay, { data: queryLogbookByDayResponse, isError: queryLogbookByDayError }] = api.endpoints.getApiProjectsByProjectIdLogbookAndUserIdWeeksYearMonthDay.useLazyQuery();

  // Callback for setting selected log book user to current logged in user
  const setSelectedUserStateFromAffiliateId = useCallback(() => {
    setSelectedLogBookUser({
      logBookUserId: getLogbookUsersData?.users?.find((u) => u.projectAffiliateId === activeProjectAffiliateId)?.logBookUserId ?? "",
      affiliateId: activeProjectAffiliateId ?? "",
    });
  }, [activeProjectAffiliateId, getLogbookUsersData]);

  // Callback for setting selected log book user to whoever the user selected
  const setSelectedUserStateFromLogBookUserId = useCallback(
    (logBookUserId: string | undefined) => {
      setSelectedLogBookUser({
        logBookUserId: logBookUserId ?? "",
        affiliateId: getLogbookUsersData?.users?.find((u) => u.logBookUserId === logBookUserId)?.projectAffiliateId ?? "",
      });
    },
    [getLogbookUsersData]
  );

  // On response from fetching log book users, then select current logged in user
  useEffect(() => {
    if (getLogbookUsersDataError) {
      console.error(getLogbookUsersDataError);
      toastRef.current.error(tRef.current("logbook.getLogbook.error"));
    } else {
      setSelectedUserStateFromAffiliateId();
    }
  }, [getLogbookUsersData, getLogbookUsersDataError, activeProjectAffiliateId, setSelectedUserStateFromAffiliateId, toastRef, tRef]);

  // On response from fetching week for selected user
  useEffect(() => {
    if (queryLogbookByDayError) {
      console.error(queryLogbookByDayError);
      toastRef.current.error(tRef.current("logbook.getLogbook.error"));
    } else {
      setlogbookWeekData(queryLogbookByDayResponse);
      if (queryLogbookByDayResponse && queryLogbookByDayResponse.days) {
        setDateRange(formatTimestamp(queryLogbookByDayResponse.days[0].date, false, "short") + " - " + formatTimestamp(queryLogbookByDayResponse.days[6].date, false, "short"));
      }
    }
  }, [queryLogbookByDayResponse, queryLogbookByDayError, toastRef, tRef]);

  // When project, user or selected date is changed, then fetch week
  useEffect(() => {
    if (selectedLogBookUser?.logBookUserId) {
      queryLogbookByDay({
        projectId: project.id ? project.id : "",
        userId: selectedLogBookUser.logBookUserId ?? "",
        year: selectedDate.getFullYear(),
        month: selectedDate.getMonth() + 1,
        day: selectedDate.getDate(),
      });
    }
  }, [project.id, selectedLogBookUser?.logBookUserId, selectedDate, queryLogbookByDay]);

  const changeDate = (content?: "back" | "next") => {
    let date = new Date();
    if (logbookWeekData && logbookWeekData.days && content) {
      date = formatTimestampToDate(logbookWeekData.days[0].date);
    }
    if (content === "back") {
      date.setDate(date.getDate() - 7);
    } else if (content === "next") {
      date.setDate(date.getDate() + 7);
    }
    setSelectedDate(date);
  };

  const openLogbookCloseDialog = useLogbookCloseDialog({ project, data: logbookWeekData, userId: selectedLogBookUser?.logBookUserId });
  const openLogbookUnlockDialog = useLogbookUnlock({ project, data: logbookWeekData, userId: selectedLogBookUser?.logBookUserId });

  return (
    <Grid item sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", p: 1, overflowY: "auto" }} xs={true} className="logbookContent">
      {getLogbookUsersData && (
        <ProjectLogbookSelectUser
          getLogbookUsersData={getLogbookUsersData}
          project={project}
          selectedUserId={selectedLogBookUser?.logBookUserId}
          onSelectUserId={(selectedLogBookUserId) => setSelectedUserStateFromLogBookUserId(selectedLogBookUserId)}
        />
      )}
      {logbookWeekData && canViewLogbook() && (
        <Box sx={{ display: "flex", flexDirection: "column", flex: 1, p: 1 }}>
          {!!selectedLogBookUser && <ProjectLogbookStats project={project} logbookWeekData={logbookWeekData} selectedUser={selectedLogBookUser} />}
          <ProjectLogbookSelectPeriod dateRange={dateRange} logbookWeekData={logbookWeekData} onChangePeriod={changeDate} />
          {!!selectedLogBookUser && <ProjectLogbookTimeRegistration project={project} logbookWeekData={logbookWeekData} selectedUser={selectedLogBookUser} />}
          {!!selectedLogBookUser && <ProjectLogbookNotes project={project} logbookWeekData={logbookWeekData} selectedUser={selectedLogBookUser} />}
          <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between", flex: 1, mb: 2 }}>
            {logbookWeekData.closed ? (
              <IconButton onClick={openLogbookUnlockDialog} sx={{ p: 0 }} disabled={!canOpenLogbookPeriod(logbookWeekData)}>
                <LockOutlinedIcon sx={{ color: "secondary.main" }} />
              </IconButton>
            ) : (
              <LockOpenOutlinedIcon sx={{ color: "primary.main" }} />
            )}
            {canCloseLogbookPeriod(logbookWeekData, selectedLogBookUser) && !logbookWeekData.closed && (
              <Button variant="contained" onClick={openLogbookCloseDialog}>
                {t("logbook.submit")}
              </Button>
            )}
          </Box>
        </Box>
      )}
      {!getLogbookUsersData && (
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <CircularProgress size={150} />
        </Box>
      )}
    </Grid>
  );
};
