
import { Checkbox, FormControlLabel } from "@mui/material";
import { UseFormRegister } from "react-hook-form"
import { useTranslation } from "react-i18next";
import { InputContainer } from "shared/styles/input-container-style";
import { CreateProjectUserFormData } from "./project-user-form-data";

interface Props {
  register: UseFormRegister<CreateProjectUserFormData>;
  value: boolean;
  onChange: (sendInvite: boolean) => void;
}

export const ProjectUserSendInvite = (props: Props) => {
  const { register, value, onChange } = props;
  const { t } = useTranslation();

  return (
    <InputContainer>
      <FormControlLabel
        data-testid="create-project-users-send-invite"
        control={<Checkbox checked={value} onChange={(e, checked) => onChange(checked)} />}
        label={t("dashboard.users.create.captionSendInvite")} />
    </InputContainer>
  );
}