import { UpdateProfitShareConfigRequest, usePutApiAdministrationProfitshareMutation } from "api/generatedApi";
import { useTranslation } from "react-i18next";
import { useDialog } from "shared/dialog/use-dialog";
import { ResultDialogType, useMutation } from "utils/hooks/use-mutation";
import { AdminProfitShareDialog } from "./admin-profit-share-dialog";

interface Props {
  data?: UpdateProfitShareConfigRequest;
}

export const useAdminProfitShare = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();
  const [updateProfitShare] = usePutApiAdministrationProfitshareMutation();
  const [openDialog, closeDialog] = useDialog(AdminProfitShareDialog);

  const handleSuccess = () => {
    closeDialog();
  };

  const wrapMutation = useMutation({
    onSuccess: handleSuccess,
    successProps: {
      description: t("admin.profitShare.edit.success"),
    },
    resultDialogType: ResultDialogType.Toast,
  });

  const handleOnSubmit = (data: UpdateProfitShareConfigRequest) => {
    wrapMutation(
      updateProfitShare({
        updateProfitShareConfigRequest: data,
      })
    );
  };

  const handleClick = () => {
    openDialog({ onSubmit: handleOnSubmit, data });
  };

  return handleClick;
};
