import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { ProjectFolderResponse } from "api/generatedApi";
import DialogTitleStyled from "components/shared/dialog/dialog-title-styled";
import { FormDialogProps } from "shared/dialog/types";
import { InputContainer } from "shared/styles/input-container-style";
import { TextField } from "@mui/material";

export interface MultiplyFolderFormData {
  factor: number;
}

interface Props extends FormDialogProps<MultiplyFolderFormData> {
  folder: ProjectFolderResponse;
}

export function MultiplyFolder(props: Props) {
  const { folder } = props;
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<MultiplyFolderFormData>({
    mode: "all",
  });

  const onSubmit: SubmitHandler<MultiplyFolderFormData> = (data) => {
    props.onSubmit(data);
  };

  const hasError = errors.factor?.message !== undefined;

  return (
    <Dialog fullWidth maxWidth="xs" open={props.isOpen}>
      <DialogTitleStyled title={t("folder.multiply.dialog.title")} description={t("folder.multiply.dialog.description")} onClose={props.onClose} isOpen={props.isOpen} showDivider={false} />
      <DialogContent>
        <InputContainer>
          <TextField
            {...register("factor", {
              validate: (value) => {
                const n = Math.floor(value);
                return (n != Infinity && n === value && n > 0) || t("folder.multiply.dialog.validateText");
              },
              valueAsNumber: true,
            })}
            type="number"
            variant="filled"
            label={t("folder.multiply.dialog.label")}
            defaultValue={folder.multiplicationFactor}
            helperText={errors.factor?.message ?? t("folder.multiply.dialog.helperText")}
            error={hasError}
          />
        </InputContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>
          {t("common.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)} disabled={hasError}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
