import { useTranslation } from "react-i18next";
import { Control, Controller } from "react-hook-form";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { InputContainer } from "shared/styles/input-container-style";
import { ExtraWorkAgreementResponse } from "api/generatedApi";
import { ExtraWorkAgreementFormData } from "../hooks/use-input-validation";
import { useExtraWorkAgreementFormatter } from "../../hooks/format";
import { MenuItem, Select, InputLabel } from '@mui/material';

interface Props {
  control: Control<ExtraWorkAgreementFormData>;
  disabled: boolean;
  agreement?: ExtraWorkAgreementResponse;
}

export const EditExtraWorkAgreementSelectType = (props: Props) => {
  const { t } = useTranslation();
  const { getTypeFormatted, getTypeByAgreementFormatted } = useExtraWorkAgreementFormatter();

  return (
    <InputContainer>
      {props.disabled ? (
        <Typography variant="body1">{getTypeByAgreementFormatted(props.agreement)}</Typography>
      ) : (
        <FormControl>
          <InputLabel>{props.disabled ? t("dashboard.extraWorkAgreements.create.type.noSelectLabel") : t("captions.typeRequired")}</InputLabel>
          <Select label="extraWorkAgreementType" defaultValue={props.agreement?.extraWorkAgreementType ?? props.control._defaultValues.extraWorkAgreementType ?? 'CustomerHours'} {...props.control.register("extraWorkAgreementType")}>
            <MenuItem value={"CustomerHours"}>{getTypeFormatted("CustomerHours")}</MenuItem>
            <MenuItem value={"CompanyHours"}>{getTypeFormatted("CompanyHours")}</MenuItem>
            <MenuItem value={"AgreedPayment"}>{getTypeFormatted("AgreedPayment")}</MenuItem>
            <MenuItem value={"Other"}>{getTypeFormatted("Other")}</MenuItem>
          </Select>
        </FormControl>
      )}
    </InputContainer>
  );
};
