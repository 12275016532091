import { ProjectUserResponse, usePutApiProjectsByProjectIdUsersUserMutation } from "api/generatedApi";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDialog } from "shared/dialog/use-dialog";
import { ResultDialogType, useMutation } from "utils/hooks/use-mutation";
import { CreateProjectUserFormData } from "../../create/inputs/project-user-form-data";
import { EditProjectUserDialog } from "../edit-project-user";

interface Props {
  projectId: string;
  user: ProjectUserResponse;
  userEmails: (string | null | undefined)[];
  isOwner: boolean;
}

export function useEditProjectUser(props: Props) {
  const { projectId, user, userEmails, isOwner } = props;
  const { t } = useTranslation();

  const [openEditDialog, closeEditDialog] = useDialog(EditProjectUserDialog);
  const [editUser] = usePutApiProjectsByProjectIdUsersUserMutation();

  const wrapMutation = useMutation({
    onSuccess: closeEditDialog,
    successProps: {
      description: t("dashboard.users.edit.success"),
    },
    errorProps: {
      description: t("dashboard.users.edit.error"),
    },
    resultDialogType: ResultDialogType.Toast,
  });

  const handleOnSubmit: SubmitHandler<CreateProjectUserFormData> = (data) => {
    wrapMutation(
      editUser({
        projectId: projectId,
        updateProjectAffiliateRequest: {
          address: data.adr,
          email: data.email,
          name: data.name,
          phoneNumber: data.phone,
          affiliateId: data.affiliateId,
          affiliateRole: data.role,
        },
      })
    );
  };

  const handleClick = () => {
    openEditDialog({ onSubmit: handleOnSubmit, user: user, userEmails: userEmails, isOwner });
  };

  return handleClick;
}
