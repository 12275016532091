import { useGetApiConfigQuery } from "api/generatedApi";


export const useFeatures = () => {
  const { data: configData } = useGetApiConfigQuery();

  //check wether a given feature is enabled
    const isFeatureEnabled = (featureName: string) => {
      // If we are not in production, all features are enabled
      if (process.env.NODE_ENV !== "production") {
        return true;
      }

      const filteredStrings = configData?.featureFlags?.enabledFeatures?.filter((str: string) => str.toLowerCase().includes(featureName.toLowerCase()));
      if (!filteredStrings) {
        return false;
      }
      return filteredStrings.length > 0;
    };

  return { isFeatureEnabled };
};
