import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import styled from "@mui/system/styled";

export const defaultDate = "01-01-0001";
export const defaultValue = 0;

export const StyledAdminContent = styled(Box)`
  background-color: lightgray;
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 8px;
`;

export const StyledListWrapper = styled(Box)`
  border: 1px solid black;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
`;

export const DialogList = styled(Box)`
  border: 1px solid black;
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 5px;
  position: relative;
`;

export const FlexBetweenCenter = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const StyledDialogTextfield = styled(TextField)`
  margin-bottom: 20px;
  width: 100%;
`;

export const StyledListButton = styled(Button)`
  margin-bottom: 20px;
`;
