import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import styled from "@mui/system/styled";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumberSelectorInput, SizeEnum } from "components/shared/number-selecter/number-selecter-input";
import { FormDataWorkItem } from "../create-work-item-form-data";

export interface Props {
  setValue: UseFormSetValue<FormDataWorkItem>;
  getValue: UseFormGetValues<FormDataWorkItem>;
}

export function SelectAmountStep(props: Props) {
  const { setValue, getValue } = props;
  const { t } = useTranslation();

  const DividerStyled = styled(Divider)`
    margin-top: 10px;
    margin-bottom: 10px;
  `;

  const getWorkTimeTypeCaption = (): string => {
    const workitemType = getValue("workitemType");
    if (!workitemType) {
      return "";
    }

    switch (workitemType) {
      case "Material":
        return "content.measurements.create.selectAmountStep.material";
      case "Operation":
        return "content.measurements.create.selectAmountStep.operation";
      case "ProjectSpecificOperation":
        return "content.measurements.create.selectAmountStep.projectSpecificOperation";
      default:
        return "";
    }
  };

  const getWorkItemTypeName = (): string | undefined | null => {
    const workitemType = getValue("workitemType");
    if (!workitemType) {
      return "";
    }

    switch (workitemType) {
      case "Material":
        return getValue("material.name");
      case "Operation":
        return getValue("operation.operationText");
      case "ProjectSpecificOperation":
        return getValue("projectSpecificOperation.projectSpecificOperationText");
      default:
        return "";
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", padding: "20px" }}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="overline">{t(getWorkTimeTypeCaption())}</Typography>
        <Typography data-testid="create-workitem-amount-operation" variant="body1" color="primary.main">
          {getWorkItemTypeName()}
        </Typography>
      </Box>
      <DividerStyled sx={{ pt: 1 }} />
      <Box sx={{ display: "flex", flexDirection: "column", pt: 1 }}>
        <Typography variant="caption" color={"primary.main"}>
          {t("content.measurements.create.selectAmountStep.header")}
        </Typography>
        <Box sx={{ pt: 5, pb: 6, display: "flex", justifyContent: "center" }}>
          <NumberSelectorInput data-testid="create-workitem-mountingcode-amount" defaultValue={getValue("amount")} setFocus={true} onChange={(value) => setValue("amount", value)} size={SizeEnum.Large} />
        </Box>
      </Box>
    </Box>
  );
}
