import { TableCell, TableRow, Typography } from "@mui/material";
import { ImportReferenceResponse } from "api/generatedApi";
import { useViewImportReference } from "../detail-view/hooks/use-view-import-reference";
import { formatDate, formatTimestampToDate } from "utils/formats";
import { useImportReferenceFormatter } from "../hooks/format";

interface PropsRow {
  item: ImportReferenceResponse
}

export const AdminImportTableRow = ({ item }: PropsRow) => {
  const openImportReference = useViewImportReference({ importReference: item })
  const { getStatusFormatted } = useImportReferenceFormatter();

  return (
    <TableRow data-testid={`admin-import-history-row-${item.importReferenceId}`} onClick={openImportReference}>
      <TableCell sx={{ maxWidth: "100px" }}>
        <Typography variant="body2">{item.materialPublishId}</Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: "100px" }}>
        <Typography variant="body2">{formatDate(formatTimestampToDate(item.date!), true)}</Typography>
      </TableCell>
      <TableCell sx={{ maxWidth: "100px" }}>
        <Typography variant="body2">{item.status && getStatusFormatted(item.status)}</Typography>
      </TableCell>
    </TableRow>
  );
}