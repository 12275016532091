const invitation_link_name = "redirect_uri_invitation";
const invitation_domain_name = "login_domain";

/**
 *
 * @param domain Signin domain hint
 * @returns void
 */
export function saveSignInDomainHint(domain?: string) {
  if (!domain) {
    return;
  }
  window.localStorage.setItem(invitation_domain_name, domain);
}

export function getLastSigninDomainHint() {
  let host = window.localStorage.getItem(invitation_domain_name);
  if (host) {
    window.localStorage.removeItem(invitation_domain_name);
    return host;
  }
}

export function saveInvitationRedirectUri(link?: string) {
  let uri = link ?? window.location.href.split("redirect_uri=");
  var url = link ?? uri[uri.length - 1];
  if (url) {
    window.localStorage.setItem(invitation_link_name, url);
  }
  return url;
}

export function getInvitationRedirectUri() {
  return window.localStorage.getItem(invitation_link_name);
}

export function useInvitationRedirectIfAny() {
  let url = window.localStorage.getItem(invitation_link_name);
  if (!url || !url.includes(window.location.origin)) {
    return;
  }
  window.localStorage.removeItem(invitation_link_name);
  window.localStorage.removeItem(invitation_domain_name);
  window.location.href = url;
}
