import { UpdateBaseRateAndSupplementConfigRequest, usePutApiAdministrationBaserateandsupplementMutation } from "api/generatedApi";
import { useTranslation } from "react-i18next";
import { useDialog } from "shared/dialog/use-dialog";
import { ResultDialogType, useMutation } from "utils/hooks/use-mutation";
import { AdminBaseRateAndSupplementsDialog } from "./admin-base-rate-and-supplements-dialog";

interface Props {
  data?: UpdateBaseRateAndSupplementConfigRequest;
}

export const useAdminBaseRateAndSupplements = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();
  const [updateBaseRateAndSupplement] = usePutApiAdministrationBaserateandsupplementMutation();
  const [openDialog, closeDialog] = useDialog(AdminBaseRateAndSupplementsDialog);

  const handleSuccess = () => {
    closeDialog();
  };

  const wrapMutation = useMutation({
    onSuccess: handleSuccess,
    successProps: {
      description: t("admin.baseRateAndSupplements.edit.success"),
    },
    resultDialogType: ResultDialogType.Toast,
  });

  const handleOnSubmit = (data: UpdateBaseRateAndSupplementConfigRequest) => {
    wrapMutation(
      updateBaseRateAndSupplement({
        updateBaseRateAndSupplementConfigRequest: data,
      })
    );
  };

  const handleClick = () => {
    openDialog({ onSubmit: handleOnSubmit, data });
  };

  return handleClick;
};
