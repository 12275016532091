import { WorkItemResponse } from "api/generatedApi";
import { useTranslation } from "react-i18next";

interface Props {
  workItem: WorkItemResponse;
}

export function useWorkitem(props: Props) {
  const { workItem } = props;
  const { t } = useTranslation();

  const workItemIsRecognised = workItem.workItemIsRecognised?.value ?? true;
  const eanIsRecognised = workItem.workItemIsRecognised?.materialWorkItemIsRecognised?.eanIsRecognised ?? true;
  const mountingCodeIsRecognised = workItem.workItemIsRecognised?.materialWorkItemIsRecognised?.mountingCodeIsRecognised ?? true;
  const operationIsRecognised = workItem.workItemIsRecognised?.operationWorkItemIsRecognised?.value ?? true;
  const supplementsAreRecognised = workItem.workItemIsRecognised?.supplementWorkItemIsRecognisedResponse?.map((x) => x.value ?? true);
  const supplementOperationsAreRecognised = workItem.workItemIsRecognised?.supplementOperationIsRecognisedResponse?.map((x) => x.value ?? true);

  const workItemTitle = `${workItem.workItemText ?? ""} ${!workItemIsRecognised ? "- " + t("content.measurements.table.importedFromCalculation") : ""}`;

  return { workItemIsRecognised, eanIsRecognised, mountingCodeIsRecognised, operationIsRecognised, supplementOperationsAreRecognised, supplementsAreRecognised, workItemTitle };
}
