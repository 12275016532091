import Grid from "@mui/material/Grid";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { GetProjectResponse, ValidationError, useGetApiConfigQuery, useGetApiProjectsByProjectIdFoldersAndFolderIdWorkitemsQuery } from "api/generatedApi";
import { CardWithHeaderAndFooter } from "components/shared/card/card-header-footer";
import { useTranslation } from "react-i18next";
import { useDashboardRestrictions } from "shared/user-restrictions/use-dashboard-restrictions";
import { useRef, useState } from "react";
import { useUploadCalculation } from "../hooks/use-upload-calculation";
import { useToast } from "shared/toast/hooks/use-toast";
import { ExtendedProjectFolder } from "pages/project/hooks/use-map-tree-to-flat";
import { useProjectCalculation } from "components/page/projects/hooks/use-project-calculation";
import { ProjectShowCalculationContent } from "./show-calculation-content";

interface Props {
  project: GetProjectResponse;
  selectedFolder: ExtendedProjectFolder;
}

export const ProjectUploadCalculation = ({ project, selectedFolder }: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [fileUploading, setFileUploading] = useState(false);
  const { projectHasCalculation } = useProjectCalculation(project);
  const { canUploadCalculation } = useDashboardRestrictions(project);
  const { data: configData } = useGetApiConfigQuery();
  const clickUploadCalculation = useUploadCalculation(project, configData?.maxUploadFileSizeMB);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { data: workItemData } = useGetApiProjectsByProjectIdFoldersAndFolderIdWorkitemsQuery(
    {
      folderId: selectedFolder.projectFolderId ?? "",
      projectId: project.id ?? "",
    },
    { refetchOnMountOrArgChange: true, skip: !selectedFolder.projectFolderId }
  );

  const projectHasWorkItemData: boolean = (workItemData?.workItems?.length ?? 0) > 0;

  const uploadCalculation = () => {
    if (fileUploading) return;
    if (!fileInputRef?.current) return;
    fileInputRef.current.click();
  };

  const handleFileChange = async (event: any) => {
    setFileUploading(true);
    clickUploadCalculation(event)
      .then((filename) => {
        if (!filename || filename === "") throw new Error();
        toast.success(t("dashboard.calculation.upload.documentUploaded", { filename: filename }));
      })
      .catch((error: ValidationError | RangeError | undefined) => {

          if (error instanceof RangeError) {
            toast.error(t("dashboard.calculation.upload.uploadFileSizeError", { maxFileSize: configData?.maxUploadFileSizeMB ?? 10 }));
          } else if (error?.exceptionDetails && Object.hasOwn(error.exceptionDetails, "additionalInformation")) {
            const toastMessage: string[] = [];
            toastMessage.push(t(`dashboard.calculation.upload.errorValidationCode.${error.exceptionDetails?.code?.toString()}`, t("dashboard.calculation.upload.uploadDefaultError")));

            if (Object.hasOwn(error.exceptionDetails?.additionalInformation, "ErrorMessages")) {
              toastMessage.push(" ");
              toastMessage.push(t("dashboard.calculation.upload.uploadTroubleshooting"));
              error.exceptionDetails?.additionalInformation?.["ErrorMessages"].forEach((message: string) => {
                toastMessage.push(message);
              });
            }

            toast.error(toastMessage.join("\n"));
          } else {
            toast.error(t("dashboard.calculation.upload.uploadDefaultError"));
            console.error(error);
          }
      })
      .finally(() => {
        setFileUploading(false);
      });
  };

  const fileUploadIcon = (
    <Box sx={{ position: "relative" }}>
      <FileUploadOutlinedIcon />
      {fileUploading && <CircularProgress size={30} sx={{ position: "absolute", top: -3, left: -3, zIndex: 1 }} />}
    </Box>
  );

  const noContentText = () => {
    if (project.pieceworkType !== "TwelveOneA") return t("dashboard.calculation.wrongAgreementType");
    if ((selectedFolder.subFolders && selectedFolder.subFolders.length > 0) || projectHasWorkItemData) return t("dashboard.calculation.existingFoldersError");

    return t("dashboard.calculation.noCalculation");
  };

  const showHeaderAction = () => {
    // only upload on 12.1A projects without existing calculation and folders
    if (project.pieceworkType !== "TwelveOneA") return false;
    if (projectHasCalculation() || projectHasWorkItemData) return false;
    if (selectedFolder.subFolders && selectedFolder.subFolders.length > 0) return false;

    // only show upload button if user has permission
    if (!canUploadCalculation()) return false;

    // show upload button
    return true;
  };

  return (
    <Grid item xs={12} lg={4} xl={4} display={"flex"} justifyContent={"center"}>
      <CardWithHeaderAndFooter
        titleNamespace={t("dashboard.calculation.title")}
        description={""}
        height={"400px"}
        headerActionIcon={fileUploadIcon}
        showHeaderAction={showHeaderAction()}
        showBottomAction={"none"}
        headerActionClickedProps={uploadCalculation}
        bottomActionClickedProps={() => {}}
        showContent={projectHasCalculation()}
        noContentText={noContentText()}
        showDescription={false}
        hasChildPadding={true}
      >
        {projectHasCalculation() && <ProjectShowCalculationContent project={project} />}
      </CardWithHeaderAndFooter>
      {!projectHasCalculation() && <input style={{ display: "none" }} ref={fileInputRef} type="file" accept=".json" onChange={handleFileChange} />}
    </Grid>
  );
};
