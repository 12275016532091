import { Box, Card, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Logo from "assets/icons/Login_logo.svg";
import LoginImage from "assets/images/login.jpg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLandingHook } from "./hooks/use-landing-hook";

export const LandingPageInvites = () => {
  const { t } = useTranslation();
  const { handleRedirect, validationStatus } = useLandingHook();

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  return (
    <Paper
      sx={{
        backgroundImage: `url(${LoginImage})`,
        width: "100%",
        height: "100vh",
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: 0,
      }}
    >
      <Card variant="outlined" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "692px", height: "456px" }}>
        <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100%" }}>
            <Typography variant="h4" sx={{ marginBottom: 3, fontWeight: "200", fontSize: "24px", color: "primary.main" }}>
              {t("login.welcome")}
            </Typography>
            <Box component="img" sx={{ width: "420px", marginBottom: 3 }} alt="Logo" src={Logo} />
            {validationStatus === "awaiting" && (
              <Typography variant="h5" align="center" sx={{ color: "grey.50", marginBottom: 3, pr: 4, pl: 4, mt: 2 }}>
                {t("redirect.awaiting")}
              </Typography>
            )}
            {validationStatus === "success" && (
              <Typography variant="h5" align="center" sx={{ color: "grey.50", marginBottom: 3, pr: 4, pl: 4, mt: 2 }}>
                {t("redirect.success")}
              </Typography>
            )}
            {validationStatus === "invalid" && (
              <Typography variant="h5" align="center" sx={{ color: "grey.50", marginBottom: 3, pr: 4, pl: 4, mt: 2 }}>
                {t("redirect.invalid")}
              </Typography>
            )}
            {validationStatus === "expired" && (
              <Typography variant="h5" align="center" sx={{ color: "grey.50", marginBottom: 3, pr: 4, pl: 4, mt: 2 }}>
                {t("redirect.expired")}
              </Typography>
            )}
          </Box>
        </Box>
      </Card>
    </Paper>
  );
};
