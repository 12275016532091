import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "shared/app-routes";
import { DialogProvider } from "shared/dialog/dialog-provider";
import { store } from "store/store";
import { theme } from "theme/theme";
import { getPCA, logoutAllTabs } from "index";
import { MsalProvider } from "@azure/msal-react";
import { ToastProvider } from "shared/toast/provider";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { CircuitBreaker } from "utils/connectivity/circuit-breaker";
import { UnauthenticatedAppRoutes } from "shared/unauthenticated-app-routes";
import { DesktopTreeProvider } from "context/desktop-tree/desktop-tree.context";
import { NotFound as NotFoundPage } from "pages/NotFound";

export const App = () => {
  // Multi tabs logout
  React.useEffect(() => {
    logoutAllTabs();
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <CircuitBreaker errorThreshold={5} initialDelay={5000}>
            <ToastProvider>
              <DesktopTreeProvider>
                <DialogProvider>
                  <MsalProvider instance={getPCA()}>
                    <AuthenticatedTemplate>
                      <AppRoutes />
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                      <UnauthenticatedAppRoutes />
                    </UnauthenticatedTemplate>
                  </MsalProvider>
                </DialogProvider>
              </DesktopTreeProvider>
            </ToastProvider>
          </CircuitBreaker>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export const NotFound = () => {
  return (
    <BrowserRouter>
      <NotFoundPage />
    </BrowserRouter>
  );
};
