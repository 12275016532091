import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import type { FormDialogProps } from "shared/dialog/types";
import CloseIcon from "@mui/icons-material/Close";
import type { CreateProjectFolderRequest, ProjectFolderResponse } from "api/generatedApi";
import FormHelperText from "@mui/material/FormHelperText";
import { useKeypressEnter } from "components/shared/keypress-enter/use-keypress-enter";

export const folderNameMaxLength = 50;

export interface CreateFolderFormData extends CreateProjectFolderRequest {
  folderDescription: string;
}

interface Props extends FormDialogProps<CreateFolderFormData> {
  isEdit?: boolean;
  folder?: ProjectFolderResponse;
}

export function FolderCreateEdit(props: Props) {
  const { isEdit, folder, isOpen, onClose } = props;
  const { t } = useTranslation();

  const {
    formState: { isValid, isSubmitting },
    watch,
    register,
    getValues,
    handleSubmit,
  } = useForm<CreateFolderFormData>({
    mode: "all",
  });

  const onSubmit: SubmitHandler<CreateFolderFormData> = async (data) => {
    await props.onSubmit(data);

    return new Promise<void>((resolve) => {
      resolve();
    });
  };

  const onSubmitHandler = () => {
    handleSubmit(onSubmit(getValues()));
  };

  useKeypressEnter(onSubmitHandler);

  const watchTitle = watch("folderName");

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen}>
      <DialogTitle component="div">
        <Typography variant="h5" color="primary.dark" sx={{ marginBottom: 2 }}>
          {isEdit && t("folder.createEdit.titleEdit")}
          {!isEdit && t("folder.createEdit.titleCreate")}
        </Typography>
        <IconButton onClick={onClose} sx={{ position: "absolute", top: 0, right: 0 }}>
          <CloseIcon />
        </IconButton>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Typography variant="caption" color={"primary.main"}>
          {t("folder.createEdit.nameLabel")}
        </Typography>
        <TextField
          {...register("folderName", {
            required: true,
          })}
          variant="filled"
          sx={{ width: "100%" }}
          defaultValue={isEdit ? folder?.projectFolderName : ""}
          label={t("folder.createEdit.name")}
          required
          inputProps={{ maxLength: folderNameMaxLength }}
          inputRef={(input) => input?.focus()}
        />
        <FormHelperText variant="filled" sx={{ display: "flex", justifyContent: "flex-end" }}>
          {watchTitle ? watchTitle.length : 0}/{folderNameMaxLength}
        </FormHelperText>
        <Typography variant="subtitle1">{t("common.note")}</Typography>
        <Typography variant="body2" sx={{ marginBottom: 1 }}>
          {t("folder.createEdit.description.description")}
        </Typography>
        <Typography variant="caption" color={"primary.main"}>
          {t("captions.note")}
        </Typography>
        <TextField {...register("folderDescription")} variant="filled" sx={{ width: "100%" }} multiline minRows={4} label={t("folder.createEdit.description.input")} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" disabled={!isValid || isSubmitting} onClick={handleSubmit(onSubmit)}>
          {isEdit && t("common.save")}
          {!isEdit && t("common.create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
