import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { GetProjectResponse, useDeleteApiProjectsByProjectIdMutation } from "api/generatedApi";
import { ResultDialogType, useMutation } from "utils/hooks/use-mutation";
import { useToast } from "shared/toast/hooks/use-toast";
import { useProjectRestrictions } from "shared/user-restrictions/use-project-restrictions";
import { useConfirm } from "components/shared/alert/confirm/hooks/use-confirm";

interface Props {
  project: GetProjectResponse;
}

export const useDeleteProject = (props: Props) => {
  const { project } = props;
  const { t } = useTranslation();
  const toast = useToast();
  const { canDeleteProject } = useProjectRestrictions(project);
  const [deleteProject] = useDeleteApiProjectsByProjectIdMutation();
  const [projectId, setProjectId] = useState("");
  const [concent, setConcent] = useState(false);

  const wrapMutation = useMutation({
    onSuccess: () => {},
    successProps: {
      title: t("project.delete.success.title"),
      description: t("project.delete.success.description"),
    },
    resultDialogType: ResultDialogType.Dialog,
  });

  useEffect(() => {
    handleDeleteProject();
  }, [projectId, concent]);

  const openConfirmDialog = useConfirm({
    title: t("project.delete.title"),
    description: t("project.delete.description"),
    submitButtonLabel: t("common.delete"),
    submit: () => {
      setConcent(true);
    },
  });

  const handleClick = (projectId: string | undefined) => {
    if (projectId) {
      setProjectId(projectId);
      openConfirmDialog();
    }
  };

  const handleDeleteProject = () => {
    if (projectId && canDeleteProject() && concent) {
      if (!canDeleteProject()) {
        toast.error(t("project.create.restrictedError"));
        return;
      }
      wrapMutation(
        deleteProject({
          projectId,
        })
      );
    }
  };

  return handleClick;
};
