import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { GetProjectResponse, ProjectUserResponse } from "api/generatedApi";
import { sortCompareBoolean, sortCompareString } from "utils/compares";
import { useOrder } from "shared/table/use-order";
import { MobileUserRow } from "./mobile-user-row";
import { useFormatUser } from "./create/hooks/use-format-users";
import { TableHeaderConfig } from "shared/table/table-header-type";
import { TableHeader } from "shared/table/table-header";
import { Grid, Button } from "@mui/material";
import { useDashboardRestrictions } from "shared/user-restrictions/use-dashboard-restrictions";

interface Props {
  users: ProjectUserResponse[];
  project: GetProjectResponse;
  isOwner: boolean;
  isProjectManager: boolean;
}

export const MobileProjectUsers = (props: Props) => {
  type UsersSortableId = "Name" | "Role" | "Invite";

  const { users, project, isOwner, isProjectManager } = props;
  const { t } = useTranslation();
  const { formatRole } = useFormatUser();
  const { direction, orderBy, getLabelProps } = useOrder<UsersSortableId>("Name");

  const { canViewChangeProjectOwnerButton } = useDashboardRestrictions(project);

  const sortedData = useMemo(() => {
    var sortedList = users;

    switch (orderBy) {
      case "Name":
        sortedList = [...users].sort((a, b) => sortCompareString(direction, a?.name, b?.name));
        break;
      case "Role":
        sortedList = [...users].sort((a, b) => sortCompareString(direction, formatRole(a.role), formatRole(b.role)));
        break;
      case "Invite":
        sortedList = [...users].sort((a, b) => sortCompareBoolean(direction, a.role !== "Owner" && a.status !== "Accepted", b.role !== "Owner" && b.status !== "Accepted"));
        break;
    }

    return sortedList;
  }, [users, orderBy, direction, formatRole]);

  const headerConfig: TableHeaderConfig<UsersSortableId>[] = [
    {
      id: "Name",
      title: t("common.name"),
      testid: "dashboard-users-header-name",
      sortable: true,
      alignment: "left",
    },
    {
      id: "Role",
      title: t("dashboard.users.table.role"),
      testid: "dashboard-users-header-role",
      sortable: true,
      alignment: "left",
    },
    {
      id: "Invite",
      title: t("dashboard.users.table.invite"),
      testid: "dashboard-users-header-invite",
      sortable: true,
      alignment: "right",
    },
  ];

  return (
    <Box sx={{ flex: 1, overflowY: "hidden", display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
      {canViewChangeProjectOwnerButton() && (
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="body1" color="primary.main" sx={{ pt: 2, pb: 2 }}>
              {t("dashboard.users.description")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Typography variant="h5" color="primary.dark" sx={{ pt: 1, pl: 1 }}>
        {t("dashboard.links.users")}
      </Typography>
      <Typography variant="body1" color="primary.main" sx={{ pt: 2, pb: 2, pl: 1 }}>
        {t("dashboard.users.description")}
      </Typography>
      <TableContainer sx={{ height: "calc(100vh - 240px)", overflowY: "auto", pb: "90px" }} component={Paper}>
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHeader headerConfig={headerConfig} getLabelProps={getLabelProps} />
          <TableBody>
            {sortedData?.map((user, index) => {
              return <MobileUserRow key={index} user={user} projectId={project.id ?? ""} isOwner={isOwner} isProjectManager={isProjectManager} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
