import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { ExtraWorkAgreementResponse, GetProjectResponse } from "api/generatedApi";
import { formatHoursAndMinutes, formatNumberToPrice } from "utils/formats";
import { useUpdateExtraWorkAgreement } from "../edit/hooks/use-update-extra-work-agreement";
import { useExtraWorkAgreementFormatter } from "../hooks/format";
import { approvalStatusMap } from "../edit/inputs/agreement-status";
import { useTranslation } from "react-i18next";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";

interface Props {
  project: GetProjectResponse;
  row: ExtraWorkAgreementResponse;
}

export const DesktopExtraWorkAgreementRow = (props: Props) => {
  const { project, row } = props;
  const { getTypeByAgreementFormatted } = useExtraWorkAgreementFormatter();
  const openUpdateExtraWorkAgreementDialog = useUpdateExtraWorkAgreement({ project, agreement: row });
  const { t } = useTranslation();

  const statusTranslation = approvalStatusMap.get(row?.approvalStatus ?? "");

  const sxProps: SxProps<Theme> = {
    cursor: "pointer"
  }
  if (row.approvalStatus === "Cancelled") {
    sxProps.opacity = 0.6;
  }
  return (
    <TableRow
      data-testid={`dashboard-extra-work-agreement-row-${row.name}`}
      onClick={() => {
        openUpdateExtraWorkAgreementDialog();
      }}
      sx={sxProps}
    >
      <TableCell align="left" sx={{ pr: 5 }} data-testid="dashboard-extra-work-agreement-row-internalid">
        {row.extraWorkAgreementInternalNumber}
      </TableCell>
      <TableCell align="left" sx={{ pr: 5 }} data-testid="dashboard-extra-work-agreement-row-id">
        {row.extraWorkAgreementNumber}
      </TableCell>
      <TableCell data-testid="dashboard-extra-work-agreement-row-name">{row.name}</TableCell>
      <TableCell data-testid="dashboard-extra-work-agreement-row-type">{getTypeByAgreementFormatted(row)}</TableCell>
      <TableCell align="right" sx={{ pr: 5 }} data-testid="dashboard-extra-work-agreement-row-hours">
        {formatHoursAndMinutes(row.workTime?.hours, row.workTime?.minutes)}
      </TableCell>
      <TableCell align="right" sx={{ pr: 5 }} data-testid="dashboard-extra-work-agreement-row-payment">
        {formatNumberToPrice(row.paymentDkr)}
      </TableCell>
      <TableCell data-testid="dashboard-extra-work-agreement-row-status">{statusTranslation && t(statusTranslation)}</TableCell>
    </TableRow>
  );
};
