import { TableHead, TableRow, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Data {
  time: Date;
  successfulRequests: number;
  failedRequests: number;
}

interface EnhancedTableProps {
  rowCount: number;
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "time",
    numeric: false,
    disablePadding: true,
    label: "admin.monitoring.metrics.tableHeaders.time",
  },
  {
    id: "successfulRequests",
    numeric: true,
    disablePadding: false,
    label: "admin.monitoring.metrics.tableHeaders.successfulRequests",
  },
  {
    id: "failedRequests",
    numeric: true,
    disablePadding: false,
    label: "admin.monitoring.metrics.tableHeaders.failedRequests",
  },
];

export function ServerMetricsTableHead(props: EnhancedTableProps) {
  const { t } = useTranslation();
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.numeric ? "right" : "left"} padding={headCell.disablePadding ? "none" : "normal"}>
            {t(headCell.label)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
