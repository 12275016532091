import React from "react";

import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LoginImage from "assets/images/login.jpg";

export const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleReload = React.useCallback(() => {
    navigate(0);
  }, [navigate]);

  return (
    <Paper
      sx={{
        backgroundImage: `url(${LoginImage})`,
        width: "100%",
        height: "100dvh",
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: 0,
      }}
    >
      <Card variant="outlined" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", px: 4, py: 8, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          {t("errorPage.title")}
        </Typography>
        <Typography variant="body1" sx={{ marginBottom: 4 }}>
          {t("errorPage.description")}
        </Typography>
        <Button variant="contained" onClick={handleReload}>
          {t("errorPage.reload")}
        </Button>
      </Card>
    </Paper>
  );
};
