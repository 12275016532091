import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { GetProjectSpecificOperationResponse, GetProjectResponse } from "api/generatedApi";
import { useOrder } from "shared/table/use-order";
import { sortCompareNumber, sortCompareString } from "utils/compares";
import { MobileProjectSpecificOperationRow } from "./mobile-project-specific-operation-row";
import { TableHeaderConfig } from "shared/table/table-header-type";
import { TableHeader } from "shared/table/table-header";
import { TextIcon } from "components/shared/text-icon/text-icon";
import IconButton from "@mui/material/IconButton";
import { useDashboardRestrictions } from "shared/user-restrictions/use-dashboard-restrictions";
import { useDeleteProjectSpecificOperations } from "./hooks/use-delete-projects-specific-operations";

interface Props {
  project: GetProjectResponse;
  compensations: GetProjectSpecificOperationResponse[];
}
type ProjectSpecificOperationSortableId = "Name" | "Payment";

export const MobileProjectSpecificOperations = (props: Props) => {
  const { project, compensations } = props;

  const { t } = useTranslation();
  const { canSelectProjectSpecificOperations, canDeleteProjectSpecificOperations } = useDashboardRestrictions(project);
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set<string>());
  const [checkedAll, setCheckedAll] = useState(false);
  const { direction, orderBy, getLabelProps } = useOrder<ProjectSpecificOperationSortableId>("Name");
  const openDeletePeorjectsSpecificOperationsDialog = useDeleteProjectSpecificOperations({ project, selectedIds });

  const onCheck = useCallback(
    (id: string | undefined) => {
      if (id === undefined) return;
      let ids = new Set(selectedIds);
      if (ids.has(id)) {
        ids.delete(id);
      } else {
        ids.add(id);
      }
      setSelectedIds(ids);
    },
    [selectedIds]
  );

  const onCheckAll = (checked: boolean) => {
    let ids = new Set<string>();
    if (checked) {
      compensations?.forEach((item) => ids.add(item.projectSpecificOperationId ?? ""));
    }
    setSelectedIds(ids);
    setCheckedAll(!checkedAll);
  };

  const isCompensationPaymentChecked = (id: string | undefined): boolean => {
    return selectedIds.has(id ?? "");
  };

  const sortedData = useMemo(() => {
    var sortedList = compensations;

    switch (orderBy) {
      case "Name":
        sortedList = [...compensations].sort((a, b) => sortCompareString(direction, a?.name, b?.name));
        break;
      case "Payment":
        sortedList = [...compensations].sort((a, b) => sortCompareNumber(direction, a?.payment, b?.payment));
        break;
    }

    return sortedList;
  }, [compensations, orderBy, direction]);

  const headerConfig: TableHeaderConfig<ProjectSpecificOperationSortableId>[] = [
    {
      id: "Name",
      title: t("common.name"),
      testid: "dashboard-project-specific-operations-header-name",
      sortable: true,
      alignment: "left",
    },
    {
      id: "Payment",
      title: t("common.currency"),
      testid: "dashboard-project-specific-operations-header-currency",
      sortable: true,
      alignment: "right",
    },
  ];

  return (
    <Box sx={{ flex: 1, overflowY: "hidden", display: "flex", flexDirection: "column", width: "100%", height: "100%", pl: 1, pr: 1 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="body1" color="primary.main">
          {t("dashboard.projectSpecificOperations.description")}
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
          <TextIcon translateText="common.delete">
            <IconButton data-testid="dashboard-project-specific-operations-delete-rows" disabled={!canDeleteProjectSpecificOperations(selectedIds)} onClick={openDeletePeorjectsSpecificOperationsDialog}>
              <DeleteOutlinedIcon />
            </IconButton>
          </TextIcon>
        </Box>
      </Box>
      <TableContainer sx={{ height: "calc(100vh - 240px)", overflowY: "auto", pb: "50px" }} component={Paper}>
        <Table stickyHeader>
          <TableHeader method="checkbox" headerConfig={headerConfig} getLabelProps={getLabelProps} checked={selectedIds.size === compensations?.length} checkBoxDisabled={!canSelectProjectSpecificOperations()} onCheckAllProps={onCheckAll} />
          <TableBody>
            {sortedData.map((operation) => {
              return <MobileProjectSpecificOperationRow key={operation.projectSpecificOperationId} project={project} row={operation} onCheck={onCheck} checked={isCompensationPaymentChecked(operation.projectSpecificOperationId)} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
