import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { ExtraWorkAgreementResponse, GetProjectResponse } from "api/generatedApi";
import { formatHoursAndMinutes, formatNumberToPrice } from "utils/formats";
import { useUpdateExtraWorkAgreement } from "../edit/hooks/use-update-extra-work-agreement";
import { useExtraWorkAgreementFormatter } from "../hooks/format";
import { SxProps, Theme } from "@mui/material";
import {useTranslation} from "react-i18next";
import {approvalStatusMap} from "../edit/inputs/agreement-status";

interface Props {
  project: GetProjectResponse;
  row: ExtraWorkAgreementResponse;
}

export const MobileExtraWorkAgreementRow = (props: Props) => {
  const { project, row } = props;
  const { getTypeByAgreementFormatted } = useExtraWorkAgreementFormatter();
  const openUpdateExtraWorkAgreementDialog = useUpdateExtraWorkAgreement({ project, agreement: row });
  const { t } = useTranslation();
  const statusTranslation = approvalStatusMap.get(row?.approvalStatus ?? "");

  const sxProps: SxProps<Theme> = {
    cursor: "pointer"
  }
  if (row.approvalStatus === "Cancelled") {
    sxProps.opacity = 0.6;
  }
  return (
    <TableRow
      data-testid={`dashboard-extra-work-agreement-row-${row.name}`}
      onClick={() => {
        openUpdateExtraWorkAgreementDialog();
      }}
      sx={sxProps}
    >
      <TableCell data-testid="dashboard-extra-work-agreement-row-name" title={row.name ?? ""}>
        <Typography variant="body2">{row.name}</Typography>
        <Typography variant="body2" color={"primary.main"}>
          {row.extraWorkAgreementNumber}
        </Typography>
        <Typography variant="caption" color={"primary.main"}>
          {getTypeByAgreementFormatted(row)}
        </Typography>
          
        <Typography variant="caption" color={"primary.main"} display="block">
          {statusTranslation && t(statusTranslation)}
        </Typography>
      </TableCell>
      <TableCell data-testid="dashboard-extra-work-agreement-row-payment" title={row.name ?? ""} align="right" sx={{ pr: 5 }}>
        <Typography variant="body2">{formatNumberToPrice(row.paymentDkr)}</Typography>
        <Typography variant="caption" color={"primary.main"}>
          {formatHoursAndMinutes(row.workTime?.hours, row.workTime?.minutes)}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
