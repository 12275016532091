import { useContext, createContext, useState } from "react";

export interface DesktopTreeContextProps {
  children: React.ReactNode;
}

interface DesktopTreeContextValues {
  collapsedIds: string[];
  onSetCollapsedIds: (nodeIds: string[]) => void;
  scrollPosition: number;
  onSetScrollPosition: (position: number) => void;
}

export const DesktopTreeContext = createContext<DesktopTreeContextValues>({} as DesktopTreeContextValues);

export const DesktopTreeConsumer = DesktopTreeContext.Consumer;

export const DesktopTreeProvider = (props: DesktopTreeContextProps) => {
  const { children } = props;

  const [collapsedIds, setCollapsedIds] = useState<string[]>([]);
  const [scrollPosition, setScrollPosition] = useState<number>(0);

  const handleSetCollapsedIds = (nodeIds: string[]) => {
    setCollapsedIds(nodeIds);
  };

  const handleSetScrollPosition = (position: number) => {
    setScrollPosition(position);
  };

  return <DesktopTreeContext.Provider value={{ collapsedIds, onSetCollapsedIds: handleSetCollapsedIds, onSetScrollPosition: handleSetScrollPosition, scrollPosition }}>{children}</DesktopTreeContext.Provider>;
};

export const useDesktopTree = (): DesktopTreeContextValues => {
  return useContext(DesktopTreeContext);
};
