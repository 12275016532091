import { UpdateSupplementsConfigRequest, usePutApiAdministrationSupplementsMutation } from "api/generatedApi";
import { useTranslation } from "react-i18next";
import { useDialog } from "shared/dialog/use-dialog";
import { ResultDialogType, useMutation } from "utils/hooks/use-mutation";
import { AdminSupplementsDialog } from "./admin-supplements-dialog";

interface Props {
  data?: UpdateSupplementsConfigRequest;
}

export const useAdminSupplements = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();
  const [updateSupplements] = usePutApiAdministrationSupplementsMutation();
  const [openDialog, closeDialog] = useDialog(AdminSupplementsDialog);

  const handleSuccess = () => {
    closeDialog();
  };

  const wrapMutation = useMutation({
    onSuccess: handleSuccess,
    successProps: {
      description: t("admin.supplements.edit.success"),
    },
    resultDialogType: ResultDialogType.Toast,
  });

  const handleOnSubmit = (data: UpdateSupplementsConfigRequest) => {
    wrapMutation(
      updateSupplements({
        updateSupplementsConfigRequest: data,
      })
    );
  };

  const handleClick = () => {
    openDialog({ onSubmit: handleOnSubmit, data });
  };

  return handleClick;
};
