import { ExtraWorkAggreementApprovalStatus, ExtraWorkAgreementResponse, ExtraWorkAgreementTypeRequest } from "api/generatedApi";
import { useTranslation } from "react-i18next";

export const useExtraWorkAgreementFormatter = () => {
  const { t } = useTranslation();

  const getTypeByAgreementFormatted = (row?: ExtraWorkAgreementResponse | null): string => {
    if (!row?.extraWorkAgreementType) {
      return "";
    }
    return getTypeFormatted(row.extraWorkAgreementType);
  };

  const getTypeFormatted = (type: ExtraWorkAgreementTypeRequest): string => {
    switch (type) {
      case "AgreedPayment":
        return t("dashboard.extraWorkAgreements.types.agreedPayment");
      case "CompanyHours":
        return t("dashboard.extraWorkAgreements.types.companyHours");
      case "CustomerHours":
        return t("dashboard.extraWorkAgreements.types.customerHours");
      case "Other":
        return t("dashboard.extraWorkAgreements.types.other");
    }
  };

  const getStatusFormatted = (status: ExtraWorkAggreementApprovalStatus): string => {
    switch (status) {
      case "Approved":
        return t("dashboard.extraWorkAgreements.approvalStatuses.approved");
      case "Rejected":
        return t("dashboard.extraWorkAgreements.approvalStatuses.rejected");
      case "AwaitingApproval":
        return t("dashboard.extraWorkAgreements.approvalStatuses.awaitingApproval");
      case "UnderDevelopment":
        return t("dashboard.extraWorkAgreements.approvalStatuses.underDevelopment");
      case "Cancelled":
        return t("dashboard.extraWorkAgreements.approvalStatuses.cancelled");
    }
  }

  const getAuditLogStatusFormatted = (eventType: string) => {
    switch (eventType) {
      case "Create":
        return t("dashboard.extraWorkAgreements.history.status.create");
      case "Update":
        return t("dashboard.extraWorkAgreements.history.status.update");
      case "Update Status":
        return t("dashboard.extraWorkAgreements.history.status.updateStatus");
      default:
        return eventType;
    }
  }

  return { getTypeFormatted, getTypeByAgreementFormatted, getStatusFormatted, getAuditLogStatusFormatted };
};
