import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import type { GetMaterialResponse, GetOperationResponse, GetProjectResponse, ProjectFolderResponse, ProjectSpecificOperationAndFavoriteResponse, WorkItemResponse } from "api/generatedApi";
import { useKeypressEnter } from "components/shared/keypress-enter/use-keypress-enter";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { FormDialogProps } from "shared/dialog/types";
import { ScreenSizeEnum, useScreenSize } from "shared/use-screen-size";
import { formatTimestampToDate, shortDateToDate } from "utils/formats";
import { FormDataWorkItemUpdate } from "../../create/create-work-item-dialog/create-work-item-form-data";
import { SelectAmountStep } from "../../create/create-work-item-dialog/step-amount/select-amount";
import { CalculationForPreviewStep } from "../../create/create-work-item-dialog/step-calculate-work-item-preview/calculate-work-item-preview-step";
import { SelectDatesStep } from "../../create/create-work-item-dialog/step-dates/select-dates";
import { WorkItemInfoStep } from "../../create/create-work-item-dialog/step-info/info-step";
import { SelectMountingCodeStep } from "../../create/create-work-item-dialog/step-mounting-code/select-mounting-code-step";
import { SelectSupplementOperationsStep } from "../../create/create-work-item-dialog/step-supplement-operations/select-supplement-operations-step";
import { SelectSupplementsStep } from "../../create/create-work-item-dialog/step-supplements/select-supplements-step";
import {
  lastStep,
  noMountingCodeSelected,
  stepMaterialAmount,
  stepMaterialCalculatedWorkItemPreview,
  stepMaterialDates,
  stepMaterialMountingCode,
  stepMaterialSupplementOperations,
  stepMaterialSupplements,
  stepOperationAmount,
  stepOperationCalculatedWorkItemPreview,
  stepOperationDates,
  stepOperationSupplements,
  stepShowInfo,
} from "../../measurements.helpers";
import { ExtendedSupplementOperationResponse, UseMapWorkItem } from "../../use-map-work-item";
interface Props extends FormDialogProps<FormDataWorkItemUpdate> {
  workItem: WorkItemResponse;
  project?: GetProjectResponse;
  folder?: ProjectFolderResponse;
  materialData?: GetMaterialResponse;
  operationData?: GetOperationResponse;
  projectSpecificOperationsData?: ProjectSpecificOperationAndFavoriteResponse;
}

export function EditWorkItemDialog(props: Props) {
  const { workItem, project, folder, materialData, operationData, projectSpecificOperationsData } = props;
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();
  const [disableSave, setDisableSave] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const { mapPreselectedSupplementOperations } = UseMapWorkItem();
  const [validationError, setValidationError] = useState<string | undefined>(undefined);

  const { getValues, setValue, watch, control } = useForm<FormDataWorkItemUpdate>({
    mode: "all",
    defaultValues: {
      workItem: workItem,
      workitemType: workItem.workItemType,
      amount: workItem.workItemAmount,
      material: workItem.workItemType === "Material" ? (materialData ? materialData : { name: workItem.workItemText }) : undefined,
      operation: workItem.workItemType === "Operation" ? (operationData ? operationData : { operationText: workItem.workItemText }) : undefined,
      projectSpecificOperation:
        workItem.workItemType === "ProjectSpecificOperation"
          ? projectSpecificOperationsData
            ? { projectSpecificOperationId: projectSpecificOperationsData.catalogId, projectSpecificOperationNumber: projectSpecificOperationsData.number, projectSpecificOperationText: projectSpecificOperationsData.text }
            : { projectSpecificOperationText: workItem.workItemText }
          : undefined,
      oldAmount: workItem.workItemAmount,
      preSelectedSupplements: workItem.supplements ?? [],
      mountingCode: workItem.workItemMaterial?.workItemMountingCode ? { mountingCode: workItem.workItemMaterial?.workItemMountingCode, text: workItem.workItemMaterial?.workItemMountingCodeText, operationTimeMilliseconds: 0 } : noMountingCodeSelected,
      date: workItem.workItemDate,
      lastUpdated: workItem.workItemType === "ProjectSpecificOperation" ? projectSpecificOperationsData?.lastUpdated ?? "" : undefined,
    },
  });

  const isMaterialWorkItem = useCallback((): boolean => {
    return getValues("workitemType") === "Material";
  }, [getValues]);

  useEffect(() => {
    if (materialData) {
      const supplementOperations = [];
      const mounting = materialData.mountings?.find((mounting) => mounting.mountingCode === workItem.workItemMaterial?.workItemMountingCode);
      if (mounting) {
        supplementOperations.push(...((mounting.supplementOperations ?? []).map((el) => ({ ...el })) as ExtendedSupplementOperationResponse[]));
      }
      const unknownSupplementOperations = (workItem.workItemMaterial?.supplementOperations ?? []).filter((x) => x.catalogSupplementOperationId == '00000000-0000-0000-0000-000000000000');
      supplementOperations.push(...(unknownSupplementOperations as ExtendedSupplementOperationResponse[]));

      const preSelectedSupplementOperations = workItem.workItemMaterial?.supplementOperations ?? undefined;
      setValue("supplementOperations", mapPreselectedSupplementOperations(supplementOperations, preSelectedSupplementOperations));
    }
  }, [mapPreselectedSupplementOperations, materialData, setValue, workItem.workItemMaterial?.supplementOperations, workItem.workItemMaterial?.workItemMountingCode]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "mountingCode") {
        const selectedMountingCode = value.mountingCode;
        const oldUsedMountingCodeForSupplementOperations = value.supplementOperationsUsedMountingCode;
        if (!selectedMountingCode && oldUsedMountingCodeForSupplementOperations) {
          setValue("supplementOperationsUsedMountingCode", undefined);
          setValue("supplementOperations", undefined);
        }
        if (selectedMountingCode && selectedMountingCode.mountingCode !== oldUsedMountingCodeForSupplementOperations) {
          setValue("supplementOperationsUsedMountingCode", selectedMountingCode.mountingCode);
          setValue("supplementOperations", selectedMountingCode?.supplementOperations ? (selectedMountingCode?.supplementOperations as ExtendedSupplementOperationResponse[]) : undefined);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const getHeader = (): string => {
    switch (activeStep) {
      case stepShowInfo:
        return t("content.measurements.info.headerFlow");
    }
    switch (getValues("workitemType")) {
      case "Material":
        return getMaterialHeader();
      case "Operation":
      case "ProjectSpecificOperation":
        return getOperationHeader();
    }
  };

  const getMaterialHeader = (): string => {
    switch (activeStep) {
      case stepMaterialAmount:
        return t("content.measurements.create.selectAmountStep.header");
      case stepMaterialMountingCode:
        return t("content.measurements.create.selectMountingCodesStep.header");
      case stepMaterialSupplementOperations:
        return t("content.measurements.create.selectSupplementOperationStep.header");
      case stepMaterialSupplements:
        return t("content.measurements.create.selectSupplementsStep.header");
      case stepMaterialDates:
        return t("content.measurements.create.selectDatesStep.header");
      case stepMaterialCalculatedWorkItemPreview:
        return t("content.measurements.create.header");
    }
    return t("content.measurements.create.header");
  };

  const getOperationHeader = (): string => {
    switch (activeStep) {
      case stepOperationAmount:
        return t("content.measurements.create.selectAmountStep.header");
      case stepOperationSupplements:
        return t("content.measurements.create.selectSupplementsStep.header");
      case stepOperationDates:
        return t("content.measurements.create.selectDatesStep.header");
      case stepOperationCalculatedWorkItemPreview:
        return t("content.measurements.create.header");
    }
    return t("content.measurements.create.header");
  };

  const getContent = () => {
    if (activeStep < stepShowInfo) {
      switch (getValues("workitemType")) {
        case "Material":
          return getMaterialContent();
        case "Operation":
        case "ProjectSpecificOperation":
          return getOperationContent();
      }
    }
    if (activeStep === stepShowInfo) {
      return <WorkItemInfoStep getValue={getValues} />;
    }
    return <Box>{`${t("content.measurements.create.unknownStep")} - Step[${activeStep}]`}</Box>;
  };

  const getMaterialContent = () => {
    switch (activeStep) {
      case stepMaterialAmount:
        return <SelectAmountStep setValue={setValue} getValue={getValues} />;
      case stepMaterialMountingCode:
        return <SelectMountingCodeStep setValue={setValue} getValue={getValues} />;
      case stepMaterialSupplementOperations:
        return <SelectSupplementOperationsStep setValue={setValue} getValue={getValues} control={control} />;
      case stepMaterialSupplements:
        return <SelectSupplementsStep setValue={setValue} getValue={getValues} control={control} />;
      case stepMaterialDates:
        return <SelectDatesStep setValue={setValue} getValue={getValues} validationError={validationError} />;
      case stepMaterialCalculatedWorkItemPreview:
        return <CalculationForPreviewStep getValue={getValues} setValue={setValue} onPreviewCalculatedProps={handlePreviewCalculated} projectId={project?.id ?? ""} folderId={folder?.projectFolderId ?? ""} workItemType={getValues("workitemType")} />;
      default:
        return <Box>{`${t("content.measurements.create.unknownStep")} - Material Step[${activeStep}]`}</Box>;
    }
  };

  const getOperationContent = () => {
    switch (activeStep) {
      case stepOperationAmount:
        return <SelectAmountStep setValue={setValue} getValue={getValues} />;
      case stepOperationSupplements:
        return <SelectSupplementsStep setValue={setValue} getValue={getValues} control={control} />;
      case stepOperationDates:
        return <SelectDatesStep setValue={setValue} getValue={getValues} validationError={validationError} />;
      case stepOperationCalculatedWorkItemPreview:
        return <CalculationForPreviewStep getValue={getValues} setValue={setValue} onPreviewCalculatedProps={handlePreviewCalculated} projectId={project?.id ?? ""} folderId={folder?.projectFolderId ?? ""} workItemType={getValues("workitemType")} />;
      default:
        return <Box>{`${t("content.measurements.create.unknownStep")} - Operation Step[${activeStep}]`}</Box>;
    }
  };

  const isDateBetweenLastUpdatedAndToday = useCallback(() => {
    const from = formatTimestampToDate(getValues("lastUpdated"));
    from.setHours(0, 0, 0, 0);
    const check = shortDateToDate(getValues("date") ?? "", "dd-mm-yyyy");
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return from <= check && check <= now;
  }, [getValues]);

  const validateMaterialStep = useCallback(() => {
    if (activeStep === stepMaterialDates && !isDateBetweenLastUpdatedAndToday()) {
      setValidationError(t("content.measurements.create.selectDatesStep.getLastUpdatedError"));
      return false;
    }
    return true;
  }, [activeStep, isDateBetweenLastUpdatedAndToday, t]);

  const validateOperationStep = useCallback(() => {
    if (activeStep === stepOperationDates && !isDateBetweenLastUpdatedAndToday()) {
      setValidationError(t("content.measurements.create.selectDatesStep.getLastUpdatedError"));
      return false;
    }
    return true;
  }, [activeStep, isDateBetweenLastUpdatedAndToday, t]);

  const validateStep = useCallback(() => {
    switch (getValues("workitemType")) {
      case "Material":
        return validateMaterialStep();
      case "Operation":
      case "ProjectSpecificOperation":
        return validateOperationStep();
    }
  }, [getValues, validateMaterialStep, validateOperationStep]);

  const onSubmit = useCallback(() => {
    if (!validateStep()) {
      return;
    }
    setDisableSave(true);
    props.onSubmit(getValues());
  }, [getValues, props, validateStep]);

  const handleNext = useCallback(() => {
    if (!validateStep()) {
      return;
    }

    setValidationError(undefined);
    setActiveStep((prevActiveStep) => {
      if (isMaterialWorkItem()) {
        if (activeStep === stepMaterialMountingCode) {
          const mountingCode = getValues("mountingCode.mountingCode");
          const supplements = getValues("supplementOperations");
          if (!mountingCode || mountingCode <= 0) {
            return stepMaterialSupplements;
          }
          if (!supplements || supplements.length <= 0) {
            return stepMaterialSupplements;
          }
        }
      }
      return prevActiveStep + 1;
    });
  }, [activeStep, getValues, isMaterialWorkItem, validateStep]);

  const handleNextAndSaveByEnter = useCallback(() => {
    if (activeStep === stepShowInfo) {
      onSubmit();
    } else {
      handleNext();
    }
  }, [activeStep, handleNext, onSubmit]);

  useKeypressEnter(handleNextAndSaveByEnter);

  const handleBack = () => {
    setValidationError(undefined);
    setActiveStep((prevActiveStep) => {
      if (isMaterialWorkItem()) {
        if (activeStep === stepMaterialSupplements) {
          const mountingCode = getValues("mountingCode.mountingCode");
          const suplements = getValues("supplementOperations");
          if (!mountingCode || mountingCode <= 0) {
            return stepMaterialMountingCode;
          }
          if (!suplements || suplements.length <= 0) {
            return stepMaterialMountingCode;
          }
        }
      }
      if (prevActiveStep === stepShowInfo) {
        switch (getValues("workitemType")) {
          case "Material":
            return stepMaterialDates;
          case "Operation":
          case "ProjectSpecificOperation":
            return stepOperationDates;
        }
      }
      return prevActiveStep - 1;
    });
  };

  const handlePreviewCalculated = () => {
    if (isMaterialWorkItem() && activeStep === stepMaterialCalculatedWorkItemPreview) {
      setActiveStep(stepShowInfo);
    }
    if (getValues("workitemType") === "Operation" && activeStep === stepOperationCalculatedWorkItemPreview) {
      setActiveStep(stepShowInfo);
    }
    if (getValues("workitemType") === "ProjectSpecificOperation" && activeStep === stepOperationCalculatedWorkItemPreview) {
      setActiveStep(stepShowInfo);
    }
  };

  const showBack = (): boolean => {
    switch (getValues("workitemType")) {
      case "Material":
        return activeStep > stepMaterialAmount;
      case "Operation":
      case "ProjectSpecificOperation":
        return activeStep > stepOperationAmount;
    }
  };

  const showSave = () => {
    return activeStep === stepShowInfo;
  };

  const showSaveAndClose = () => {
    switch (getValues("workitemType")) {
      case "Material":
        return activeStep >= stepMaterialAmount && activeStep < stepMaterialCalculatedWorkItemPreview;
      case "Operation":
      case "ProjectSpecificOperation":
        return stepOperationAmount && activeStep < stepMaterialCalculatedWorkItemPreview;
    }
  };

  const showNext = () => {
    return activeStep !== stepMaterialCalculatedWorkItemPreview && activeStep !== lastStep && activeStep !== stepShowInfo;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          height: screenSize === ScreenSizeEnum.Mobile ? "100%" : "750px", // mobile is ignored
          margin: screenSize === ScreenSizeEnum.Mobile ? "32px 7px" : "32px",
          width: screenSize === ScreenSizeEnum.Mobile ? "100%" : "calc(100%-64px)",
        },
      }}
      open={props.isOpen}
    >
      <DialogTitle component="div" textAlign={"center"}>
        <Typography variant="h5" color="primary.dark">
          {getHeader()}
        </Typography>
        <IconButton onClick={props.onClose} sx={{ position: "absolute", top: 0, right: 0 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent data-testid="is-the-the-container" sx={{ padding: 0 }}>
        {getContent()}
      </DialogContent>
      <DialogActions>
        {showBack() && (
          <Button data-testid="create-workitem-action-btn-back" disabled={disableSave} variant="outlined" onClick={handleBack}>
            {t("common.back")}
          </Button>
        )}
        {showSave() && (
          <Button data-testid="create-workitem-action-btn-save" disabled={disableSave} variant="contained" color="primary" onClick={onSubmit}>
            {t("common.save")}
          </Button>
        )}
        {showSaveAndClose() && (
          <Button data-testid="create-workitem-action-btn-save" disabled={disableSave} variant="contained" color="primary" onClick={onSubmit}>
            {t("common.saveAndClose")}
          </Button>
        )}
        {showNext() && (
          <Button data-testid="create-workitem-action-btn-next" disabled={disableSave} variant="contained" onClick={handleNext}>
            {t("common.next")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
