import Box from "@mui/material/Box";
import { AdminSupplements } from "./admin-supplements/admin-supplements";
import { AdminProfitShare } from "./admin-profit-share/admin-profit-share";
import { AdminBaseRateAndSupplements } from "./admin-base-rate-and-supplements/admin-base-rate-and-supplements";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { useTranslation } from "react-i18next";

export const AdminConfiguration = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDownwardIcon />} aria-controls="panel1-content" id="panel1-header">
          <Typography variant="h5">{t("admin.supplements.title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AdminSupplements />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDownwardIcon />} id="panel2-header">
          <Typography variant="h5">{t("admin.profitShare.title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AdminProfitShare />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ArrowDownwardIcon />} id="panel3-header">
          <Typography variant="h5">{t("admin.baseRateAndSupplements.title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AdminBaseRateAndSupplements />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
