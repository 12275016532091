import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutline";
import { TextIcon } from "components/shared/text-icon/text-icon";
import { useOrder } from "shared/table/use-order";
import { sortCompareNumber, sortCompareString } from "utils/compares";
import { useOnlineStatus } from "utils/connectivity/hook/use-online-status";
import { DraftWorkItem } from "utils/offline-storage/use-offline-storage";
import { MobileDraftRowMaterial } from "../rows/mobile/mobile-draft-row-material";
import { MobileDraftRowOperation } from "../rows/mobile/mobile-draft-row-operation";
import { GetProjectResponse } from "api/generatedApi";
import { useDeleteDraftItem } from "../delete/hook/use-delete-draft-item";
import { ExtendedProjectFolder } from "pages/project/hooks/use-map-tree-to-flat";
import { useCopyDraftWorkItemToFolder } from "../move/hooks/use-copy-draft-work-items-to-folder";
import { MobileDraftRowProjectSpecificOperation } from "../rows/mobile/mobile-draft-row-project-specific-operation";
import { useFolderRestrictions } from "shared/user-restrictions/use-folder-restrictions";
import { TableHeader } from "shared/table/table-header";
import { TableHeaderConfig } from "shared/table/table-header-type";

type DraftWorktemSortableId = "material" | "amount";
interface Prop {
  project: GetProjectResponse;
  foldersFlatlist: ExtendedProjectFolder[];
  draftWorkItems: DraftWorkItem[];
  updateTable: () => void;
}

export const DraftMobile = (props: Prop) => {
  const { project, foldersFlatlist, draftWorkItems, updateTable } = props;
  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = useState<Set<string>>(new Set<string>());
  const [checkedAll, setCheckedAll] = useState(false);
  const isOnline = useOnlineStatus();
  const { direction, orderBy, getLabelProps } = useOrder<DraftWorktemSortableId>("material");
  const openDeleteItemConfirmation = useDeleteDraftItem({ project: project, selectedIds: selectedIds, updateTable: updateTable });
  const copyDraftWorkItemsToFolder = useCopyDraftWorkItemToFolder({
    project,
    foldersFlatlist,
    selectedDraftIds: Array.from(selectedIds),
  });
  const { canDeleteDraft } = useFolderRestrictions(project);

  const onCheck = useCallback(
    (id: string | undefined) => {
      if (id === undefined) return;
      let ids = new Set(selectedIds);
      if (ids.has(id)) {
        ids.delete(id);
      } else {
        ids.add(id);
      }
      setSelectedIds(ids);
    },
    [selectedIds]
  );

  const onCheckAll = (checked: boolean) => {
    let ids = new Set<string>();
    if (checked) {
      draftWorkItems?.forEach((item) => ids.add(item.draftId ?? ""));
    }
    setSelectedIds(ids);
    setCheckedAll(!checkedAll);
  };

  const isWorkItemChecked = (id: string | undefined): boolean => {
    return selectedIds.has(id ?? "");
  };

  const handleDeleteItem = () => {
    openDeleteItemConfirmation();
  };

  const NoWorkItems = styled(Box)`
    width: 100%;
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    fontstyle: italic;
  `;

  const sortedData = useMemo(() => {
    var sortedList = draftWorkItems;

    switch (orderBy) {
      case "material":
        sortedList = [...sortedList].sort((a, b) => sortCompareString(direction, a.workItemType === "Material" ? a.material?.name : a?.operation?.operationText, b.workItemType === "Material" ? b?.material?.name : b.operation?.operationText));
        break;
      case "amount":
        sortedList = [...sortedList].sort((a, b) => sortCompareNumber(direction, a?.workItemAmount, b?.workItemAmount));
        break;
    }

    return sortedList;
  }, [draftWorkItems, orderBy, direction]);

  const getDraftWorkItemRow = (draftWorkItem: DraftWorkItem) => {
    switch (draftWorkItem.workItemType) {
      case "Material":
        return <MobileDraftRowMaterial key={draftWorkItem.draftId} draftWorkItem={draftWorkItem} onCheck={onCheck} checked={isWorkItemChecked(draftWorkItem.draftId)} />;
      case "Operation":
        return <MobileDraftRowOperation key={draftWorkItem.draftId} draftWorkItem={draftWorkItem} onCheck={onCheck} checked={isWorkItemChecked(draftWorkItem.draftId)} />;
      case "ProjectSpecificOperation":
        return <MobileDraftRowProjectSpecificOperation key={draftWorkItem.draftId} draftWorkItem={draftWorkItem} onCheck={onCheck} checked={isWorkItemChecked(draftWorkItem.draftId)} />;
    }
  };

  const headerConfig: TableHeaderConfig<DraftWorktemSortableId>[] = [
    {
      id: "material",
      title: t("content.measurements.table.workItemSmall"),
      sortable: true,
      testid: "mobile-measurement-table-material",
      alignment: "left",
    },
    {
      id: "amount",
      title: t("content.measurements.table.amount"),
      sortable: true,
      testid: "mobile-measurement-table-amount",
      alignment: "right",
      width: "100px",
    },
  ];

  return (
    <Box sx={{ flex: 1, flexDirection: "column", overflowY: "scroll", display: "flex", width: "100%", height: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", flexGrow: 1, padding: "20px 10px 0px 20px" }}>
        <Typography variant="h5" color="primary.dark">
          {t("draft.title")}
        </Typography>
        <Typography variant="body1" color="grey.50" sx={{ pt: 2 }}>
          {t("draft.description")}
        </Typography>
      </Box>
      {draftWorkItems && draftWorkItems.length > 0 && (
        <Fragment>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 0.5, gap: 0.5 }}>
            <TextIcon>
              <IconButton disabled={!isOnline || selectedIds.size === 0} onClick={copyDraftWorkItemsToFolder}>
                <ContentCopyOutlinedIcon />
              </IconButton>
            </TextIcon>
            {canDeleteDraft() && (
              <TextIcon>
                <IconButton data-testid="mobile-measurement-table-delete-row" disabled={selectedIds.size === 0} onClick={handleDeleteItem}>
                  <DeleteOutlinedIcon />
                </IconButton>
              </TextIcon>
            )}
          </Box>
          <Box sx={{ height: "100%", width: "100%", padding: "0px 10px 20px 20px" }}>
            <TableContainer sx={{ overflowY: "auto", pb: "70px" }} component={Paper}>
              <Table sx={{ tableLayout: "fixed" }}>
                <TableHeader method="checkbox" headerConfig={headerConfig} narrowPadding={true} getLabelProps={getLabelProps} checked={selectedIds.size === draftWorkItems.length} checkBoxDisabled={false} onCheckAllProps={onCheckAll} />
                <TableBody>
                  {sortedData.map((draftWorkItem) => {
                    return getDraftWorkItemRow(draftWorkItem);
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Fragment>
      )}
      {draftWorkItems && draftWorkItems.length <= 0 && (
        <NoWorkItems sx={{ paddingBottom: 20 }} color={"grey.100"}>
          {t("content.measurements.noWorkItemsIsRegistred")}
        </NoWorkItems>
      )}
    </Box>
  );
};
