import { Box, Paper, Table, TableBody, TableContainer } from "@mui/material"
import { TableHeader } from "shared/table/table-header"
import { ExtraWorkAgreementHistoryTableRow } from "./history-row";
import { TableHeaderConfig } from "shared/table/table-header-type";
import { useTranslation } from "react-i18next";
import { ExtraWorkAgreementAuditLogEntry } from "api/generatedApi";

interface Props {
  auditLogEntries: ExtraWorkAgreementAuditLogEntry[];
}

export const ExtraWorkAgreementHistoryTable = (props: Props) => {
  type AgreementChangeLogSortableId = "Timestamp" | "UserName" | "EventType" | "Description";
  const { t } = useTranslation();


  const headerConfig: TableHeaderConfig<AgreementChangeLogSortableId>[] = [
    {
      id: "Timestamp",
      title: t("dashboard.extraWorkAgreements.history.header.timestamp"),
      sortable: false,
      alignment: "left",
      testid: "dashboard-extra-work-agreement-history-header-timestamp"
    },
    {
      id: "UserName",
      title: t("dashboard.extraWorkAgreements.history.header.username"),
      sortable: false,
      alignment: "left",
      testid: "dashboard-extra-work-agreement-history-header-username"
    },
    {
      id: "EventType",
      title: t("dashboard.extraWorkAgreements.history.header.eventType"),
      sortable: false,
      alignment: "left",
      testid: "dashboard-extra-work-agreement-history-header-eventtype"
    }
  ]

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHeader headerConfig={headerConfig}></TableHeader>
          <TableBody>
            {props.auditLogEntries.map(entry => {
              return <ExtraWorkAgreementHistoryTableRow key={entry.timestamp} row={entry}></ExtraWorkAgreementHistoryTableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

