import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { FormDataWorkItem } from "../create-work-item-form-data";
import { useTranslation } from "react-i18next";
import { formatDateToRequestShortDate, formatNewDate, formatTimestamp, shortDateToDate } from "utils/formats";
import { getPCA } from "index";
import { DateSelector } from "components/shared/date-selector/date-selector";
import { useGetApiCatalogMaterialsByMaterialIdQuery, useGetApiCatalogOperationsByOperationIdQuery } from "api/generatedApi";

interface Props {
  getValue: UseFormGetValues<FormDataWorkItem>;
  setValue: UseFormSetValue<FormDataWorkItem>;
  validationError?: string;
}

export function SelectDatesStep(props: Props) {
  const { getValue, setValue, validationError } = props;

  const [skipMaterial, setSkipMaterial] = React.useState(true);
  const [skipOperation, setSkipOperation] = React.useState(true);
  const { data: materialData } = useGetApiCatalogMaterialsByMaterialIdQuery({ materialId: getValue("material.id") ?? "" }, { skip: skipMaterial });
  const { data: operationData } = useGetApiCatalogOperationsByOperationIdQuery({ operationId: getValue("operation.operationId") ?? "" }, { skip: skipOperation });

  const [lastUpdatedDisplayValue, setLastUpdatedDisplayValue] = React.useState("");

  const { t } = useTranslation();

  const msalInstance = getPCA();
  const account = msalInstance.getActiveAccount();

  const workItem = getValue("workItem");

  React.useEffect(() => {
    if (getValue("lastUpdated")) {
      setLastUpdatedDisplayValue(getValue("lastUpdated") ?? "");
    } else if (getValue("workitemType") === "Material") {
      setSkipMaterial(false);
    } else if (getValue("workitemType") === "Operation") {
      setSkipOperation(false);
    }
  }, [getValue]);

  React.useEffect(() => {
    if (materialData && materialData.lastUpdated) {
      setValue("lastUpdated", materialData.lastUpdated);
      setLastUpdatedDisplayValue(materialData.lastUpdated);
    }
  }, [materialData, setValue]);

  React.useEffect(() => {
    if (operationData && operationData.lastUpdated) {
      setValue("lastUpdated", operationData.lastUpdated);
      setLastUpdatedDisplayValue(operationData.lastUpdated);
    }
  }, [operationData, setValue]);

  const getWorkItemDate = () => {
    if (getValue("date")) {
      return shortDateToDate(getValue("date") ?? "", "dd-mm-yyyy");
    } else if (workItem?.workItemDate) {
      return shortDateToDate(workItem?.workItemDate, "dd-mm-yyyy");
    } else {
      return new Date();
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", padding: "20px" }}>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h6">{t("content.measurements.create.selectDatesStep.createdAt")}</Typography>
        <Typography variant="body1" color={"primary.main"}>
          {workItem?.createdInteractionResponse?.timestamp ? formatTimestamp(workItem?.createdInteractionResponse?.timestamp) : formatNewDate()}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", pb: 4 }}>
        <Typography variant="h6">{t("content.measurements.create.selectDatesStep.createdBy")}</Typography>
        {!workItem && (
          <Typography variant="body1" color={"primary.main"}>
            {account?.name}
          </Typography>
        )}
        <Typography variant="body1" color={"primary.main"}>
          {workItem?.createdInteractionResponse?.userName ? workItem?.createdInteractionResponse?.userName : account?.username}
        </Typography>
      </Box>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h6">{t("content.measurements.create.selectDatesStep.completedAt")}</Typography>
        <Typography variant="caption" sx={{ display: "block", mb: 2 }}>{`${t("content.measurements.create.selectDatesStep.lastUpdatedText")}: ${formatTimestamp(lastUpdatedDisplayValue)}`}</Typography>
        <DateSelector defaultDate={getWorkItemDate()} onChangeProps={(value) => setValue("date", formatDateToRequestShortDate(value))} />
        {validationError && (
          <Typography variant="body2" color={"error.main"} sx={{ mt: 1 }}>
            {validationError}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", pb: 4 }}>
        <Typography variant="h6">{t("content.measurements.create.selectDatesStep.updatedAt")}</Typography>
        {!workItem && (
          <Typography variant="body1" color={"primary.main"}>
            {account?.name}
          </Typography>
        )}
        <Typography variant="body1" color={"primary.main"}>
          {workItem?.lastModifiedInteractionResponse?.userName ? workItem?.lastModifiedInteractionResponse?.userName : account?.username}
        </Typography>
      </Box>
    </Box>
  );
}
