import { GetProjectResponse, ValidationError } from "api/generatedApi";
import { makeFullPath } from "api/path";
import { getAccessToken } from "api/baseApi";
import { api } from "api/enhancedEndpoints";
import { useDispatch } from "react-redux";

export function useUploadCalculation(project: GetProjectResponse, maxFileSizeMb?: number) {
  const dispatch = useDispatch();
  const maxFileSizeBytes = (maxFileSizeMb ?? 10) * 1024 * 1024;

  const clickUploadDocument = async (event: any): Promise<string | undefined | ValidationError> => {
    event.stopPropagation();

    const url = makeFullPath(`/api/projects/${project.id}/calculations/importcalculation`);
    const target = event.target as HTMLInputElement;
    const fileObj = target?.files?.[0];

    if (!fileObj) return undefined;

    const uploadDocumentFormData = new FormData();
    uploadDocumentFormData.append("file", fileObj);

    if (fileObj.size > maxFileSizeBytes) {
      throw new RangeError();
    }

    // reset file input
    event.target.value = null;

    const token = await getAccessToken();
    const uploadedFilename = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: uploadDocumentFormData,
    })
      .then((response) => {
        if (response.ok) return response.json;
        if (response.status === 400) {
          return response.json().then((error: ValidationError) => {
            throw error;
          });
        }
        throw new Error();
      })
      .then(() => {
          dispatch(api.util.invalidateTags(["ThisProject", "DashboardSummary", "WorkItemsSummation", "Projects", "Folders"]));
        return fileObj.name;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
    return uploadedFilename;
  };

  return clickUploadDocument;
}
