import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { GetProjectResponse } from "api/generatedApi";
import { useTranslation } from "react-i18next";
import { useProjectCalculation } from "../../../hooks/use-project-calculation";

interface Props {
  project: GetProjectResponse;
}

export const ProjectShowCalculationContent = ({ project }: Props) => {
  const { t } = useTranslation();
  const { calculationFilename, calculationImportTime } = useProjectCalculation(project);

  return (
    <Grid>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ wordWrap: "break-word" }}>
          <Typography variant={"overline"} color={"primary.main"}>
            {t("dashboard.calculation.imported")}
          </Typography>
          <Typography variant={"body2"} color={"primary.main"}>
            {calculationImportTime()}
          </Typography>
        </Box>
        <Box>
          <Typography variant={"overline"} color={"primary.main"}>
            {t("dashboard.calculation.filename")}
          </Typography>
          <Typography variant={"body2"} color={"primary.main"} whiteSpace="pre-wrap" sx={{ wordBreak: "break-word" }}>
            {calculationFilename()}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};
