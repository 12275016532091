import React from "react";
import { Button, DialogActions, DialogContent, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import { UpdateProfitShareConfigRequest } from "api/generatedApi";
import DialogTitleStyled from "components/shared/dialog/dialog-title-styled";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DialogBaseProps } from "shared/dialog/types";
import { DialogList, StyledDialogTextfield, StyledListButton, defaultDate } from "../../admin.styled";

interface Props extends DialogBaseProps {
  data?: UpdateProfitShareConfigRequest;
  onSubmit: (data: UpdateProfitShareConfigRequest) => void;
}

export const AdminProfitShareDialog = (props: Props) => {
  const { data } = props;
  const { t } = useTranslation();

  const {
    formState: { isSubmitting },
    register,
    handleSubmit,
    getValues,
    control,
  } = useForm<UpdateProfitShareConfigRequest>({
    mode: "all",
    defaultValues: {
      profitShareAgreements: data?.profitShareAgreements,
    },
  });

  const { append, remove } = useFieldArray({
    control,
    name: "profitShareAgreements",
  });

  const handleAppendAgreement = () => {
    append({
      profitShareDateInterval: { startDate: defaultDate, endDate: defaultDate },
      profitShareEntries: [
        { profitShareText: { value: "" }, profitShareValue: { value: 0 }, profitSharePercentage: { value: 0 } },
        { profitShareText: { value: "" }, profitShareValue: { value: 0 }, profitSharePercentage: { value: 0 } },
        { profitShareText: { value: "" }, profitShareValue: { value: 0 }, profitSharePercentage: { value: 0 } },
      ],
    });
  };

  const onSubmit: SubmitHandler<UpdateProfitShareConfigRequest> = async (data) => {
    await props.onSubmit(data);

    return new Promise<void>((resolve) => {
      resolve();
    });
  };

  if (!data) return null;
  return (
    <Dialog maxWidth="sm" open={props.isOpen}>
      <DialogTitleStyled title={t("admin.profitShare.edit.title")} onClose={props.onClose} isOpen={props.isOpen} handleIconClose={props.onClose} />
      <DialogContent sx={{ p: 3, pr: 5 }}>
        {getValues().profitShareAgreements && (
          <React.Fragment>
            <Typography>{t("admin.profitShare.profitShareAgreements")}</Typography>
            {getValues().profitShareAgreements?.map((agreement, index) => {
              return (
                <DialogList key={index}>
                  <IconButton onClick={() => remove(index)}>
                    <DeleteIcon />
                  </IconButton>
                  <StyledDialogTextfield {...register(`profitShareAgreements.${index}.profitShareDateInterval.startDate`)} variant="filled" label={t("admin.dateIntervalStart")} defaultValue={agreement.profitShareDateInterval?.startDate} />
                  <StyledDialogTextfield {...register(`profitShareAgreements.${index}.profitShareDateInterval.endDate`)} variant="filled" label={t("admin.dateIntervalEnd")} defaultValue={agreement.profitShareDateInterval?.endDate} />
                  <Typography>{t("admin.profitShare.profitShareEntries")}</Typography>
                  {agreement.profitShareEntries
                    ?.map((item) => ({ ...item, id: Math.floor(Math.random() * 100) }))
                    .map((entry, entryIndex) => {
                      return (
                        <DialogList key={entry.id}>
                          <StyledDialogTextfield
                            {...register(`profitShareAgreements.${index}.profitShareEntries.${entryIndex}.profitSharePercentage.value`, { valueAsNumber: true })}
                            type="number"
                            variant="filled"
                            label={t("admin.profitShare.profitSharePercentage")}
                            defaultValue={entry.profitSharePercentage}
                          />
                          <StyledDialogTextfield
                            {...register(`profitShareAgreements.${index}.profitShareEntries.${entryIndex}.profitShareText.value`)}
                            variant="filled"
                            label={t("admin.profitShare.profitShareText")}
                            defaultValue={entry.profitShareText}
                          />
                          <StyledDialogTextfield
                            {...register(`profitShareAgreements.${index}.profitShareEntries.${entryIndex}.profitShareValue.value`, { valueAsNumber: true })}
                            type="number"
                            variant="filled"
                            label={t("admin.profitShare.profitShareValue")}
                            defaultValue={entry.profitShareValue}
                          />
                        </DialogList>
                      );
                    })}
                </DialogList>
              );
            })}
            <StyledListButton variant="contained" color="primary" onClick={handleAppendAgreement}>
              {t("admin.addToList")}
            </StyledListButton>
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions sx={{ pr: 3 }}>
        <Button variant="contained" sx={{ width: "160px" }} color="primary" disabled={isSubmitting} onClick={handleSubmit(onSubmit)}>
          {t("common.saveAndClose")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
