import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Button, styled } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { ProjectUserInviteStatus, ProjectUserResponse } from "api/generatedApi";
import { useFormatUser } from "./create/hooks/use-format-users";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { useEditProjectUser } from "./edit/hooks/use-edit-project-user";
import { useConfirmInviteDialog } from "./hooks/use-confirm-dialog";
import { useTranslation } from "react-i18next";
import { useDeleteProjectUser } from "./delete/use-delete-project-user";

interface Props {
  user: ProjectUserResponse;
  userEmails: (string | null | undefined)[];
  projectId: string;
  isOwner: boolean;
  isProjectManager: boolean;
  currentUserAffiliateId: string | undefined;
}

const TableCellMaxWidth = styled(TableCell)`
  max-width: 200px;
`;

export const DesktopUserRow = (props: Props) => {
  const { user, projectId, userEmails, isOwner, isProjectManager, currentUserAffiliateId } = props;
  const { formatRole } = useFormatUser();
  const labels = useInviteStatusLabel();
  const openConfirmDialog = useConfirmInviteDialog({
    projectId: projectId,
    projectAffiliateId: user.projectAffiliateId ?? "",
    email: user.email ?? "",
  });

  const openEditUser = useEditProjectUser({ projectId, user, userEmails, isOwner });

  const openDeleteUser = useDeleteProjectUser({ projectId, user, isOwner });

  return (
    <TableRow data-testid={`dashboard-favorite-row-${user.name}`}>
      <TableCell data-testid="dashboard-users-row-name">
        <Typography variant="body2">{user.name}</Typography>
      </TableCell>
      <TableCell data-testid="dashboard-user-row-role">
        <Typography variant="body2">{formatRole(user.role)}</Typography>
      </TableCell>
      <TableCell data-testid="dashboard-user-row-status">
        <Typography variant="body2">{user.role === "Owner" ? "" : labels[user.status ?? "None"]}</Typography>
      </TableCell>
      <TableCell data-testid="dashboard-user-row-email">
        <Typography variant="body2">{user.email}</Typography>
      </TableCell>
      <TableCellMaxWidth data-testid="dashboard-user-row-adr">
        <Typography variant="body2" title={user.address ?? ""} noWrap>
          {user.address}
        </Typography>
      </TableCellMaxWidth>
      <TableCell data-testid="dashboard-user-row-phone">
        <Typography variant="body2">{user.phone}</Typography>
      </TableCell>

      <TableCell data-testid="dashboard-user-row-invite">
        {(isOwner || isProjectManager) && user.role !== "Owner" && user.status !== "Accepted" ? (
          <Button onClick={() => openConfirmDialog()}>
            <MailOutlineIcon />
          </Button>
        ) : null}
      </TableCell>
      <TableCell data-testid="dashboard-user-row-edit">
        {(isOwner || currentUserAffiliateId === user.projectAffiliateId) && (
          <Button onClick={openEditUser}>
            <Edit />
          </Button>
        )}
      </TableCell>
      <TableCell data-testid="dashboard-user-row-delete">
        {isOwner && user.role !== "Owner" && (
          <Button onClick={openDeleteUser}>
            <Delete />
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export const useInviteStatusLabel = (): Record<ProjectUserInviteStatus, string> => {
  const { t } = useTranslation();

  const labels: Record<ProjectUserInviteStatus, string> = {
    Accepted: t("dashboard.users.invite.status.accepted"),
    Created: t("dashboard.users.invite.status.created"),
    ErrorSending: t("dashboard.users.invite.status.errorSending"),
    Expired: t("dashboard.users.invite.status.expired"),
    Failed: t("dashboard.users.invite.status.failed"),
    None: t("dashboard.users.invite.status.none"),
    Sent: t("dashboard.users.invite.status.sent"),
  };

  return labels;
};
