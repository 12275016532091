import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import type { DialogBaseProps } from "shared/dialog/types";
import { GetProjectResponse } from "api/generatedApi";
import { useState } from "react";
import { ExtendedProjectFolder } from "pages/project/hooks/use-map-tree-to-flat";
import { TreeSelector } from "components/shared/tree/selector/default";
import DialogTitleStyled from "components/shared/dialog/dialog-title-styled";
import { usePaperProps } from "components/shared/dialog/paper-props";
import { useKeypressEnter } from "components/shared/keypress-enter/use-keypress-enter";
import { UseTreeFolderCounter } from "components/shared/tree-folder-counter/use-tree-folder-counter";

interface Props extends DialogBaseProps {
  project: GetProjectResponse;
  dataFlatlist: ExtendedProjectFolder[];
  selectedFolderId?: string;
  onSubmit: (destinationFolderId: string) => void;
}

export const FolderMove = (props: Props) => {
  const { project, dataFlatlist, selectedFolderId, onSubmit } = props;
  const { t } = useTranslation();
  const [moveToFolderId, setMoveToFolderId] = useState("");
  const [validationError, setValidationError] = useState<string | undefined>(undefined);
  const { paperProps } = usePaperProps();
  const { validateAppendFolderIntoFolder } = UseTreeFolderCounter();

  const exceedMaxFolders = (thisFolder?: ExtendedProjectFolder, moveToFolder?: ExtendedProjectFolder): boolean => {
    if (!thisFolder || !moveToFolder) {
      return false;
    }
    if (validateAppendFolderIntoFolder(thisFolder, moveToFolder)) {
      setValidationError(t("folder.move.exceedMaxSubFoldersError"));
      return false;
    }
    return true;
  };

  const onSubmitHandler = () => {
    if (
      !exceedMaxFolders(
        dataFlatlist.find((f) => f.projectFolderId === selectedFolderId),
        dataFlatlist.find((f) => f.projectFolderId === moveToFolderId)
      )
    ) {
      return;
    }
    onSubmit(moveToFolderId);
  };

  useKeypressEnter(onSubmitHandler);

  return (
    <Dialog maxWidth="sm" open={props.isOpen} PaperProps={paperProps}>
      <DialogTitleStyled title={t("folder.move.title")} description={t("folder.move.description")} onClose={props.onClose} isOpen={props.isOpen} />
      <DialogContent>
        <Grid item xs={12} color={"error.main"} textAlign={"center"}>
          {validationError}
        </Grid>
        <TreeSelector project={project} dataFlatlist={dataFlatlist} folderSelectedProps={(nodeId: string) => setMoveToFolderId(nodeId)} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>
          {t("common.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmitHandler} disabled={!moveToFolderId}>
          {t("common.move")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
