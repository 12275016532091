import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { GroupedWorkItemsResponse } from "api/generatedApi";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOrder } from "shared/table/use-order";
import { sortCompareNumber, sortCompareString } from "utils/compares";
import { GroupingWorkitemRow } from "./grouping-workitems-row";
import { TableHeaderConfig } from "shared/table/table-header-type";
import { TableHeader } from "shared/table/table-header";

interface Props {
  groupedWorkitems: GroupedWorkItemsResponse[];
  scroll: boolean;
  sorting: boolean;
}

export const GroupingWorkitemsTable = ({ groupedWorkitems, scroll, sorting }: Props) => {
  type WorkItemsGroupSortableId = "Text" | "Amount" | "PaymentDkr";

  const { t } = useTranslation();
  const { direction, orderBy, getLabelProps } = useOrder<WorkItemsGroupSortableId>("Text");

  const headerConfig: TableHeaderConfig<WorkItemsGroupSortableId>[] = [
    {
      id: "Text",
      title: t("content.overview.grouping.dialog.table.title"),
      sortable: sorting && true,
      testid: "overview-grouping-dialog-header-title",
      alignment: "left",
    },
    {
      id: "Amount",
      title: t("content.overview.grouping.dialog.table.quantity"),
      sortable: sorting && true,
      testid: "overview-grouping-dialog-header-amount",
      alignment: "right",
    },
    {
      id: "PaymentDkr",
      title: t("content.overview.grouping.dialog.table.payment"),
      sortable: sorting && true,
      testid: "overview-grouping-dialog-header-payment",
      alignment: "right",
    },
  ];

  const sortedData = useMemo(() => {
    var sortedList = [...groupedWorkitems];

    switch (orderBy) {
      case "Text":
        sortedList = [...groupedWorkitems].sort((a, b) => sortCompareString(direction, a?.text, b?.text));
        break;
      case "Amount":
        sortedList = [...groupedWorkitems].sort((a, b) => sortCompareNumber(direction, a?.amount, b?.amount));
        break;
      case "PaymentDkr":
        sortedList = [...groupedWorkitems].sort((a, b) => sortCompareNumber(direction, a?.paymentDkr, b?.paymentDkr));
        break;
    }

    return sortedList;
  }, [groupedWorkitems, orderBy, direction]);

  return (
    <TableContainer sx={{ maxHeight: !scroll ? "auto" : "700px", overflow: scroll && sortedData.length > 8 ? "scroll" : "hidden" }} component={Paper}>
      <Table stickyHeader>
        <TableHeader headerConfig={headerConfig} getLabelProps={getLabelProps} />
        <TableBody>
          {sortedData.map((groupedWorkItem) => {
            return <GroupingWorkitemRow key={groupedWorkItem.id} groupedWorkItem={groupedWorkItem} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
