import { Intercept } from "pages/Intercept";
import { LandingPageInvites } from "pages/LandingPageInvites";
import { Login } from "pages/Login";
import { Fragment } from "react";
import { useRoutes } from "react-router-dom";
import { BreadcrumbsRoute } from "use-react-router-breadcrumbs";

export const routes: BreadcrumbsRoute[] = [
  {
    path: "/verifyInvitation/*",
    element: <LandingPageInvites />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <Intercept />,
  },
];

export function UnauthenticatedAppRoutes() {
  let routeElement = useRoutes(routes);

  return <Fragment>{routeElement}</Fragment>;
}
