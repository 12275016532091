import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { GetProjectResponse, ProjectUserResponse } from "api/generatedApi";
import { sortCompareBoolean, sortCompareString } from "utils/compares";
import { useOrder } from "shared/table/use-order";
import { DesktopUserRow } from "./desktop-user-row";
import { useFormatUser } from "./create/hooks/use-format-users";
import { TableHeaderConfig } from "shared/table/table-header-type";
import { TableHeader } from "shared/table/table-header";
import { Button, Grid } from "@mui/material";
import { useDashboardRestrictions } from "shared/user-restrictions/use-dashboard-restrictions";

type UserSortableId = "Name" | "Role" | "InviteStatus" | "Email" | "Adr" | "Phone" | "Invite" | "Edit" | "Delete";

interface Props {
  users: ProjectUserResponse[];
  project: GetProjectResponse;
  isOwner: boolean;
  isProjectManager: boolean;
  currentUserAffiliateId: string | undefined;
}

export const DesktopProjectUsers = (props: Props) => {
  const { users, project, isOwner, isProjectManager, currentUserAffiliateId } = props;

  const { t } = useTranslation();
  const { formatRole } = useFormatUser();

  const { direction, orderBy, getLabelProps } = useOrder<UserSortableId>("Name");
  const userEmails = users.map((user) => user.email);

  const { canViewChangeProjectOwnerButton } = useDashboardRestrictions(project);

  const sortedData = useMemo(() => {
    var sortedList = users;

    switch (orderBy) {
      case "Name":
        sortedList = [...users].sort((a, b) => sortCompareString(direction, a?.name, b?.name));
        break;
      case "Role":
        sortedList = [...users].sort((a, b) => sortCompareString(direction, formatRole(a.role), formatRole(b.role)));
        break;
      case "InviteStatus":
        sortedList = [...users].sort((a, b) => sortCompareString(direction, a.status, b.status));
        break;
      case "Email":
        sortedList = [...users].sort((a, b) => sortCompareString(direction, a?.email, b?.email));
        break;
      case "Adr":
        sortedList = [...users].sort((a, b) => sortCompareString(direction, a?.address, b?.address));
        break;
      case "Invite":
        sortedList = [...users].sort((a, b) => sortCompareBoolean(direction, a.role !== "Owner" && a.status !== "Accepted", b.role !== "Owner" && b.status !== "Accepted"));
        break;
      case "Phone":
        sortedList = [...users].sort((a, b) => sortCompareString(direction, a?.phone, b?.phone));
        break;
    }

    return sortedList;
  }, [users, orderBy, direction, formatRole]);

  const headerConfig: TableHeaderConfig<UserSortableId>[] = [
    {
      id: "Name",
      title: t("common.name"),
      testid: "dashboard-users-header-name",
      sortable: true,
      alignment: "left",
    },
    {
      id: "Role",
      title: t("dashboard.users.table.role"),
      testid: "dashboard-users-header-role",
      sortable: true,
      alignment: "left",
    },
    {
      id: "InviteStatus",
      title: t("dashboard.users.table.status"),
      testid: "dashboard-users-header-status",
      sortable: true,
      alignment: "left",
    },
    {
      id: "Email",
      title: t("dashboard.users.table.email"),
      testid: "dashboard-users-header-email",
      sortable: true,
      alignment: "left",
    },
    {
      id: "Adr",
      title: t("dashboard.users.table.adr"),
      testid: "dashboard-users-header-adr",
      sortable: true,
      alignment: "left",
    },
    {
      id: "Phone",
      title: t("dashboard.users.table.phone"),
      testid: "dashboard-users-header-phone",
      sortable: true,
      alignment: "left",
    },
    {
      id: "Invite",
      title: t("dashboard.users.table.invite"),
      testid: "dashboard-users-header-invite",
      sortable: true,
      alignment: "left",
    },
    {
      id: "Edit",
      title: t("dashboard.users.table.edit"),
      testid: "dashboard-users-header-edit",
      sortable: false,
      alignment: "left",
    },
    {
      id: "Delete",
      title: t("dashboard.users.table.delete"),
      testid: "dashboard-users-header-delete",
      sortable: false,
      alignment: "left",
    },
  ];

  return (
    <Box sx={{ flex: 1, overflowY: "hidden", display: "flex", flexDirection: "column", width: "100%", height: "100%", pl: 4, pr: 4 }}>
      {canViewChangeProjectOwnerButton() && (
        <Grid container>
          <Grid item xs={8}>
            <Typography variant="body1" color="primary.main" sx={{ pt: 2, pb: 2 }}>
              {t("dashboard.users.description")}
            </Typography>
          </Grid>
        </Grid>
      )}
      <TableContainer sx={{ height: "calc(100vh - 240px)", overflowY: "auto", pb: "50px" }} component={Paper}>
        <Table stickyHeader>
          <TableHeader headerConfig={headerConfig} getLabelProps={getLabelProps} />
          <TableBody>
            {sortedData?.map((user, index) => {
              return <DesktopUserRow key={index} {...{ user, projectId: project.id ?? "", userEmails, isOwner, isProjectManager, currentUserAffiliateId }} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
