import { Box, Paper, Table, TableBody, TableContainer, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TableHeader } from "shared/table/table-header";
import { ScreenSizeEnum, useScreenSize } from "shared/use-screen-size";
import { AdminImportTableRow } from "./row/admin-import-row";
import { TableHeaderConfig } from "shared/table/table-header-type";
import { useOrder } from "shared/table/use-order";
import { useMemo } from "react";
import { sortCompareDateString, sortCompareDateTime, sortCompareNumber, sortCompareTime } from "utils/compares";
import { ImportReferenceResponse, useGetApiAdministrationImportreferencesQuery } from "api/generatedApi";
import { formatDate, formatTimestampToDate } from "utils/formats";

export enum ImportStatus {
  Pending,
  Approved
}

export const AdminImport = () => {
  type AdminImportSortableId = 'Id' | 'PublishId' | 'Date' | 'Status';
  const { t } = useTranslation();
  const { data, error: importHistoryError } = useGetApiAdministrationImportreferencesQuery(undefined);

  const { screenSize } = useScreenSize();
  const { direction, orderBy, getLabelProps } = useOrder<AdminImportSortableId>("Date");
  const padHorizontal = screenSize === ScreenSizeEnum.Mobile ? 2 : 8;
  const padVertial = screenSize === ScreenSizeEnum.Mobile ? 4 : 8;

  const headerConfigDesktop: TableHeaderConfig<AdminImportSortableId>[] = [
    {
      id: "PublishId",
      title: "PublishId",
      sortable: true,
      testid: "TestId",
      alignment: "left"
    }, {
      id: "Date",
      title: "Date",
      sortable: true,
      testid: "TestId",
      alignment: "left"
    }, {
      id: "Status",
      title: "Status",
      sortable: false,
      testid: "TestId",
      alignment: "left"
    }
  ]

  const headerConfig = headerConfigDesktop;

  const sortedData = useMemo(() => {
    let sortedList: ImportReferenceResponse[] = [];

    if (data) {
      sortedList = data.importReferences ?? [];
    }

    switch (orderBy) {
      case "PublishId":
        sortedList = [...sortedList].sort((a, b) => sortCompareNumber(direction, a.materialPublishId, b.materialPublishId));
        break;
      case "Date":
        sortedList = [...sortedList].sort((a, b) => sortCompareNumber(direction, formatTimestampToDate(a.date).getTime(), formatTimestampToDate(b.date).getTime()));
        break;
    }
    return sortedList
  }, [data, orderBy, direction]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h5">{t("admin.import.title")}</Typography>
      </Box>
      <Box pt={4} pl={padHorizontal} pr={padHorizontal}>
        <TableContainer sx={{ height: "calc(100vh - 400px)" }} component={Paper}>
          <Table stickyHeader>
            <TableHeader headerConfig={headerConfig} getLabelProps={getLabelProps} />
            <TableBody>
              {sortedData.map((data) => {
                return <AdminImportTableRow key={data.importReferenceId} item={data} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );

}