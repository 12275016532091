import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { GetProjectResponse, GetProjectSpecificOperationResponse } from "api/generatedApi";
import { formatNumberToPrice } from "utils/formats";
import { useUpdateProjectSpecificOperation } from "./edit/hooks/use-update-projects-specific-operation";
import { useDashboardRestrictions } from "shared/user-restrictions/use-dashboard-restrictions";
import Checkbox from "@mui/material/Checkbox";

interface Props {
  project: GetProjectResponse;
  row: GetProjectSpecificOperationResponse;
  onCheck: (id: string | undefined) => void;
  checked: boolean;
}

export const MobileProjectSpecificOperationRow = (props: Props) => {
  const { project, row, onCheck, checked } = props;
  const { canSelectProjectSpecificOperations } = useDashboardRestrictions(project);
  const updateDialog = useUpdateProjectSpecificOperation({ operation: row, project });

  return (
    <TableRow data-testid={`dashboard-project-specific-operation-row-${row.name}`} onClick={updateDialog}>
      <TableCell>
        <Checkbox
          data-testid="dashboard-project-specific-operation-row-checkbox"
          onChange={() => onCheck(row.projectSpecificOperationId)}
          onClick={(event) => event.stopPropagation()}
          checked={checked}
          disabled={!canSelectProjectSpecificOperations()}
        />
      </TableCell>
      <TableCell data-testid="dashboard-project-specific-operation-row-name" align="left">
        <Typography variant="body2">{row.name}</Typography>
        <Typography variant="body2" color="primary.main">
          {row.extraWorkAgreementNumber}
        </Typography>
      </TableCell>
      <TableCell data-testid="dashboard-project-specific-operation-row-payment" align="right">
        <Typography variant="body2">{formatNumberToPrice(row.payment)}</Typography>
      </TableCell>
    </TableRow>
  );
};
