import React from "react";
import { useTranslation } from "react-i18next";
import { SubmitHandler } from "react-hook-form";

import {
  GetMaterialResponse,
  GetOperationResponse,
  GetProjectResponse,
  ProjectFolderResponse,
  ProjectSpecificOperationAndFavoriteResponse,
  WorkItemResponse,
  usePutApiProjectsByProjectIdFoldersAndFolderIdWorkItemsWorkItemIdMaterialMutation,
  usePutApiProjectsByProjectIdFoldersAndFolderIdWorkItemsWorkItemIdOperationMutation,
  usePutApiProjectsByProjectIdFoldersAndFolderIdWorkItemsWorkItemIdProjectspecificoperationMutation,
} from "api/generatedApi";
import { useDialog } from "shared/dialog/use-dialog";
import { ResultDialogType, useMutation } from "utils/hooks/use-mutation";
import { useWorkItemRestrictions } from "shared/user-restrictions/use-workitems-restrictions";
import { useToast } from "shared/toast/hooks/use-toast";
import { FormDataWorkItemUpdate } from "../../create/create-work-item-dialog/create-work-item-form-data";
import { EditWorkItemDialog } from "./edit-work-item";
import { formatDateToRequestShortDate, formatTimestampToDate, shortDateToDate } from "utils/formats";
import { UseMapWorkItem } from "../../use-map-work-item";

interface Prop {
  project: GetProjectResponse;
  folder: ProjectFolderResponse;
  workItem: WorkItemResponse;
  materialData?: GetMaterialResponse;
  operationData?: GetOperationResponse;
  projectSpecificOperationsData?: ProjectSpecificOperationAndFavoriteResponse;
}

export function useEditWorkItem(props: Prop) {
  const { project, folder, workItem, materialData, operationData, projectSpecificOperationsData } = props;
  const { t } = useTranslation();
  const { canEditWorkitem } = useWorkItemRestrictions(project);
  const toast = useToast();
  const [openEditWorkItemDialog, closeEditWorkItemDialog] = useDialog(EditWorkItemDialog);
  const [updateMaterialItem] = usePutApiProjectsByProjectIdFoldersAndFolderIdWorkItemsWorkItemIdMaterialMutation();
  const [updateOperationItem] = usePutApiProjectsByProjectIdFoldersAndFolderIdWorkItemsWorkItemIdOperationMutation();
  const [updateProjectSpecificOperationItem] = usePutApiProjectsByProjectIdFoldersAndFolderIdWorkItemsWorkItemIdProjectspecificoperationMutation();
  const { mapSupplementOperationsRequests, mapSupplementRequests, mapPreselectedSupplementRequests } = UseMapWorkItem();

  const wrapMutation = useMutation({
    onSuccess: closeEditWorkItemDialog,
    successProps: {
      description: t("content.measurements.edit.success.description"),
    },
    resultDialogType: ResultDialogType.Toast,
  });

  const isDateBetweenLastUpdatedAndToday = React.useCallback((lastUpdated?: string, date?: string) => {
    const from = formatTimestampToDate(lastUpdated);
    from.setHours(0, 0, 0, 0);
    const check = shortDateToDate(date ?? "", "dd-mm-yyyy");
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return from <= check && check <= now;
  }, []);

  const callUpdateItem = (data: FormDataWorkItemUpdate) => {
    const projectId = project?.id ?? "";
    const folderId = folder?.projectFolderId ?? "";
    const workItemId = data.workItem?.workItemId ?? "";
    const workItemAmount = data.amount && data.amount > 0 ? data.amount : undefined;
    const workItemMountingCode = data.mountingCode?.mountingCode;
    const supplements = data.supplements ? mapSupplementRequests(data.supplements) : data.preSelectedSupplements ? mapPreselectedSupplementRequests(data.preSelectedSupplements) : [];
    const lastUpdated = data.lastUpdated ? data.lastUpdated : materialData ? materialData.lastUpdated : operationData ? operationData.lastUpdated : projectSpecificOperationsData ? projectSpecificOperationsData.lastUpdated : undefined;
    let workItemDate = data.date ?? formatDateToRequestShortDate(new Date());
    let isDateOverwritten = false;

    if (lastUpdated && !isDateBetweenLastUpdatedAndToday(lastUpdated, data.date)) {
      isDateOverwritten = true;
      workItemDate = formatDateToRequestShortDate(new Date());
    }

    switch (data.workitemType) {
      case "Material":
        return {
          isDateOverwritten,
          action: updateMaterialItem({
            projectId,
            folderId,
            workItemId,
            updateWorkItemMaterialRequest: {
              materialId: data.material.id,
              workItemAmount,
              workItemMountingCode,
              supplementOperations: mapSupplementOperationsRequests(data.supplementOperations),
              supplements,
              workItemDate,
            },
          }),
        };
      case "Operation":
        return {
          isDateOverwritten,
          action: updateOperationItem({
            projectId,
            folderId,
            workItemId,
            updateWorkItemOperationRequest: {
              operationId: data.operation.operationId,
              workItemAmount,
              supplements,
              workItemDate,
            },
          }),
        };
      case "ProjectSpecificOperation":
        return {
          isDateOverwritten,
          action: updateProjectSpecificOperationItem({
            projectId,
            folderId,
            workItemId,
            updateWorkItemProjectSpecificOperationRequest: {
              projectSpecificOperationId: data.projectSpecificOperation.projectSpecificOperationId,
              workItemAmount,
              supplements,
              workItemDate,
            },
          }),
        };
    }
  };

  const handleOnSubmit: SubmitHandler<FormDataWorkItemUpdate> = (data) => {
    const update = callUpdateItem(data);
    if (update) {
      wrapMutation({ unwrap: () => update.action, successMessage: update.isDateOverwritten ? t("content.measurements.edit.success.changedDateDescription") : undefined });
    }
  };

  const handleClick = () => {
    if (!canEditWorkitem(folder)) {
      toast.error(t("content.measurements.edit.restrictionError"));
      return;
    }
    openEditWorkItemDialog({ onSubmit: handleOnSubmit, workItem, project, folder, materialData, operationData, projectSpecificOperationsData });
  };

  return handleClick;
}
