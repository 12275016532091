import InfoIcon from "@mui/icons-material/Info";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Header } from "components/shared/header/header";
import { Outlet, useLocation } from "react-router-dom";
import { ErrorBoundary } from "shared/error-boundary";
import { costumPalette } from "theme/palette";
import usePwaUpdateStatus from "./hooks/use-pwa-update-status";
import { useTranslation } from "react-i18next";

export const DesktopLayout = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { showUpdateBanner } = usePwaUpdateStatus();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Header />
      {showUpdateBanner && (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="100%" height="80px" sx={{ backgroundColor: costumPalette.green }}>
          <InfoIcon sx={{ color: "white", fontSize: "40px" }} />
          <Box ml="16px" width="810px">
            <Typography variant="subtitle1" color="white">
              {t("pwa.updateMessageDesktop")}
            </Typography>
          </Box>
        </Box>
      )}
      <Box sx={{ flex: "1", display: "flex", overflow: "hidden" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Grid container direction="column" sx={{ height: "100%" }}>
            <ErrorBoundary key={location.pathname}>
              <Outlet />
            </ErrorBoundary>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
