import { useState, useEffect, useCallback } from "react";

export function useKeypressEnter(submitKeypress: () => void) {
  const [enterPressed, setEnterPressed] = useState<Boolean>(false);

  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter" && !enterPressed) {
        e.preventDefault();
        setEnterPressed(true);
        submitKeypress();
        setTimeout(() => {
          // Wait for prevent multibly enters
          setEnterPressed(false);
        }, 500);
      }
    },
    [enterPressed, setEnterPressed, submitKeypress]
  );

  useEffect(() => {
    window.addEventListener("keypress", keyPress);
    return () => window.removeEventListener("keypress", keyPress);
  }, [keyPress]);
}
