import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FieldError, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputContainer } from "../../../../../../../shared/styles/input-container-style";
import { CreateProjectUserFormData } from "./project-user-form-data";

interface Props {
  register: UseFormRegister<CreateProjectUserFormData>;
  fieldError: FieldError | undefined;
}

export const ProjectUserName = (props: Props) => {
  const { register, fieldError } = props;
  const { t } = useTranslation();

  return (
    <InputContainer height={85}>
      <Typography variant="caption" color={"primary.main"}>
        {t("captions.nameRequired")}
      </Typography>
      <TextField
        data-testid="create-project-users-name"
        {...register("name")}
        variant="filled"
        sx={{ width: "100%" }}
        label={t("dashboard.users.create.placeholderName")}
        inputProps={{ maxLength: 40 }}
        error={!!fieldError}
        helperText={fieldError?.message}
      />
    </InputContainer>
  );
};
