import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { ProjectUserResponse } from "api/generatedApi";
import { useFormatUser } from "./create/hooks/use-format-users";
import Button from "@mui/material/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useConfirmInviteDialog } from "./hooks/use-confirm-dialog";
import Box from "@mui/material/Box";
import { TableCellNoWrap } from "theme/theme";

interface Props {
  user: ProjectUserResponse;
  projectId: string;
  isOwner: boolean;
  isProjectManager: boolean;
}

export const MobileUserRow = (props: Props) => {
  const { user, projectId, isOwner, isProjectManager } = props;
  const { formatRole } = useFormatUser();

  const openConfirmDialog = useConfirmInviteDialog({
    projectId: projectId,
    projectAffiliateId: user.projectAffiliateId ?? "",
    email: user.email ?? "",
  });

  return (
    <TableRow data-testid={`mobile-user-row-${user.name}`}>
      <TableCellNoWrap data-testid="mobile-user-row-name">
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="body2" noWrap>
            {user.name}
          </Typography>
          <Typography variant="caption" color={"primary.main"} noWrap>
            {user.email}
          </Typography>
          {user.address && (
            <Typography variant="caption" color={"primary.main"} noWrap>
              {user.address}
            </Typography>
          )}
          {user.phone && (
            <Typography variant="caption" color={"primary.main"} noWrap>
              {user.phone}
            </Typography>
          )}
        </Box>
      </TableCellNoWrap>
      <TableCell data-testid="mobile-user-row-role">
        <Typography variant="body2">{formatRole(user.role)}</Typography>
      </TableCell>
      <TableCell data-testid="mobile-user-row-invite" align="right">
        {(isOwner || isProjectManager) && user.role !== "Owner" && user.status !== "Accepted" ? (
          <Button onClick={() => openConfirmDialog()}>
            <MailOutlineIcon />
          </Button>
        ) : (
          <Button disabled={true}>
            <HorizontalRuleIcon />
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};
