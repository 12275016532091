import { useTranslation } from "react-i18next";
import { ProjectUserResponse, useDeleteApiProjectsByProjectIdUsersAndAffiliateIdMutation } from "api/generatedApi";
import { ResultDialogType, useMutation } from "utils/hooks/use-mutation";
import { useToast } from "shared/toast/hooks/use-toast";
import { useConfirm } from "components/shared/alert/confirm/hooks/use-confirm";

interface Props {
  projectId: string;
  user: ProjectUserResponse;
  isOwner: boolean;
}

export function useDeleteProjectUser(props: Props) {
  const { projectId, user, isOwner } = props;
  const { t } = useTranslation();
  const toast = useToast();

  const [deleteUser] = useDeleteApiProjectsByProjectIdUsersAndAffiliateIdMutation();

  const wrapMutation = useMutation({
    successProps: {
      description: user.role === "Manager" ? t("dashboard.users.delete.success.managerDescription") : t("dashboard.users.delete.success.description"),
    },
    resultDialogType: ResultDialogType.Toast,
  });

  const handleOnSubmit = () => {
    wrapMutation(
      deleteUser({
        projectId,
        affiliateId: user.projectAffiliateId ?? "",
      })
    );
  };

  const openConfirmDialog = useConfirm({
    title: user.role === "Manager" ? t("dashboard.users.delete.managerTitle") : t("dashboard.users.delete.title"),
    description: user.role === "Manager" ? t("dashboard.users.delete.managerDescription") : t("dashboard.users.delete.description"),
    submitButtonLabel: t("common.remove"),
    submit: handleOnSubmit,
  });

  const handleClick = () => {
    if (!isOwner) {
      toast.error(t("dashboard.users.delete.restrictionError"));
      return;
    }
    openConfirmDialog();
  };

  return handleClick;
}
