import { GetProjectResponse } from "api/generatedApi";
import { Role, useRoleRestriction } from "./use-role-restriction";

export const useProjectRestrictions = (project?: GetProjectResponse) => {
  const { hasRole } = useRoleRestriction(project ?? {});

  const canCreateProject = (): boolean => {
    return true;
  };

  const canDeleteProject = (): boolean => {
    if (hasRole([Role.Owner])) {
      return true;
    }
    return false;
  };

  return { canCreateProject, canDeleteProject };
};
