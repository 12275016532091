import { GetProjectLogBookWeekQueryResponse, GetProjectResponse } from "api/generatedApi";
import { Role, useRoleRestriction } from "./use-role-restriction";
import { SelectedLogBookUser } from "components/page/projects/logbook/logbook";

export const useLogbookRestrictions = (project: GetProjectResponse) => {
  const { hasRole } = useRoleRestriction(project);
  const activeProjectAffiliateId = project.currentUserAffiliateId;

  const canSelectLogbookParticipant = (): boolean => {
    return hasRole([Role.Participant, Role.Owner, Role.Manager, Role.Inactive]);
  };

  const canViewLogbook = (): boolean => {
    return hasRole([Role.Participant, Role.Owner, Role.Manager, Role.Inactive]);
  };

  const canEditLogbook = (logbook?: GetProjectLogBookWeekQueryResponse, selectedLogBookUser?: SelectedLogBookUser): boolean => {
    if (!activeProjectAffiliateId || !selectedLogBookUser || !logbook) {
      return false;
    }

    let canEdit = hasRole([Role.Owner]) || (selectedLogBookUser.affiliateId === activeProjectAffiliateId && hasRole([Role.Owner, Role.Manager, Role.Participant]));
    if (canEdit && !logbook.closed) {
      return true;
    }

    return false;
  };

  const canCloseLogbookPeriod = (logbook?: GetProjectLogBookWeekQueryResponse, selectedLogBookUser?: SelectedLogBookUser): boolean => {
    if (!activeProjectAffiliateId || !selectedLogBookUser || !logbook) {
      return false;
    }

    let canEdit = hasRole([Role.Owner]) || (selectedLogBookUser.affiliateId === activeProjectAffiliateId && hasRole([Role.Owner, Role.Manager, Role.Participant]));
    if (canEdit && !logbook.closed) {
      return true;
    }

    return false;
  };

  const canOpenLogbookPeriod = (logbook?: GetProjectLogBookWeekQueryResponse): boolean => {
    if (!logbook?.closed) {
      return false;
    }
    return hasRole([Role.Owner]);
  };

  const canViewSalaryAdvance = (selectedLogBookUser?: SelectedLogBookUser) => {
    if (hasRole([Role.Owner])) {
      return true;
    }
    if (activeProjectAffiliateId === selectedLogBookUser?.affiliateId) {
      return true;
    }
    return false;
  };

  const canEditSalaryAdvance = (): boolean => {
    if (hasRole([Role.Owner])) {
      return true;
    }
    return false;
  };

  return {
    canSelectLogbookParticipant,
    canViewLogbook,
    canEditLogbook,
    canCloseLogbookPeriod,
    canOpenLogbookPeriod,
    canViewSalaryAdvance,
    canEditSalaryAdvance,
  };
};
