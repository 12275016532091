import { IconButton } from "@mui/material";
import { GetProjectResponse, ProjectFolderResponse } from "api/generatedApi";
import { useMultiplyFolder } from "./hooks/use-multiply-folder";
import { TextIcon } from "components/shared/text-icon/text-icon";
import { useFolderRestrictions } from "shared/user-restrictions/use-folder-restrictions";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import { t } from "i18next";

export interface Props {
  project: GetProjectResponse;
  folder: ProjectFolderResponse;
  hideText?: boolean;
}

export function MultiplyFolderButton(props: Props) {
  const { project, folder } = props;
  const { canMultiplyFolder } = useFolderRestrictions(project);

  const openDialog = useMultiplyFolder({ project, folder });

  const changeMultiplicationFactor = () => {
    if (!canMultiplyFolder()) {
      return;
    }
    openDialog();
  };

  return (
    <TextIcon translateText={props.hideText ? undefined : t("folder.multiply.buttonTitle")}>
      <IconButton onClick={changeMultiplicationFactor} disabled={!canMultiplyFolder()}>
        <FolderCopyOutlinedIcon />
      </IconButton>
    </TextIcon>
  );
}
