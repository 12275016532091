import React from "react";
import { useTranslation } from "react-i18next";
import { SubmitHandler } from "react-hook-form";
import { GetProjectResponse, usePostApiProjectsByProjectIdFavoritesMutation } from "api/generatedApi";
import { useDialog } from "shared/dialog/use-dialog";
import { ResultDialogType, useMutation } from "utils/hooks/use-mutation";
import { CreateFavoriteDialog, CreateFavoriteFormData } from "../create";
import { useToast } from "shared/toast/hooks/use-toast";
import { useOfflineStorage } from "utils/offline-storage/use-offline-storage";

const DuplicateErrorCode = 409;

interface Props {
  project: GetProjectResponse;
}

export function useCreateFavorite(props: Props) {
  const { project } = props;
  const { t } = useTranslation();

  const toast = useToast();

  const [openCreateFavorite, closeCreateFavoriteDialog] = useDialog(CreateFavoriteDialog);
  const [createFavorite] = usePostApiProjectsByProjectIdFavoritesMutation();
  const { updateOfflineFavorites } = useOfflineStorage();

  const handleSuccess = React.useCallback(() => {
    updateOfflineFavorites(project.id ?? "");
    closeCreateFavoriteDialog();
  }, [closeCreateFavoriteDialog, project.id, updateOfflineFavorites]);

  const wrapMutation = useMutation({
    onSuccess: handleSuccess,
    successProps: {
      description: t("dashboard.favorits.create.success.description"),
    },
    onError: (error) => toast.error(error?.status === DuplicateErrorCode ? t("dashboard.favorits.create.error.duplicate") : t("dashboard.favorits.create.error.default")),
    resultDialogType: ResultDialogType.Toast,
  });

  const handleOnSubmit: SubmitHandler<CreateFavoriteFormData> = (data) => {
    const favId = data.workitemType === "Material" ? data.material.id : data.operation.operationId;
    wrapMutation(
      createFavorite({
        projectId: project?.id ?? "",
        registerProjectCatalogFavoriteRequest: {
          catalogId: favId ?? "",
          catalogType: data.workitemType,
        },
      })
    );
  };

  const handleClick = () => {
    openCreateFavorite({ onSubmit: handleOnSubmit });
  };

  return handleClick;
}
