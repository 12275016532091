import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ProjectUserResponse } from "api/generatedApi";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormDialogProps } from "shared/dialog/types";
import { ScreenSizeEnum, useScreenSize } from "shared/use-screen-size";
import { useValidateProjectUserInputs } from "../create/hooks/use-input-validation";
import { ProjectUserAdr } from "../create/inputs/project-user-adr";
import { ProjectUserEmail } from "../create/inputs/project-user-email";
import { CreateProjectUserFormData } from "../create/inputs/project-user-form-data";
import { ProjectUserName } from "../create/inputs/project-user-name";
import { ProjectUserPhoneNumber } from "../create/inputs/project-user-phone-number";
import { ProjectUserRoleField } from "../create/inputs/project-user-role";
import { useFormatUser } from "../create/hooks/use-format-users";
import { useState } from "react";

interface Props extends FormDialogProps<CreateProjectUserFormData> {
  user: ProjectUserResponse;
  userEmails: (string | null | undefined)[];
  isOwner: boolean;
}

export const EditProjectUserDialog = (props: Props) => {
  const { user, onSubmit, userEmails, isOwner } = props;
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();
  const { mapUserRoleToProjectUserRole } = useFormatUser();
  const [ownerRoleChangeDismissed, setOwnerRoleChangeDismissed] = useState<boolean>(false);

  const {
    getValues,
    setValue,
    register,
    watch,
    formState: { errors, isDirty },
    setError,
    control,
    clearErrors,
  } = useForm<CreateProjectUserFormData>({
    mode: "all",
    defaultValues: {
      role: mapUserRoleToProjectUserRole(user.role),
      adr: user.address ?? "",
      email: user.email ?? "",
      name: user.name ?? "",
      phone: user.phone ?? "",
      affiliateId: user.projectAffiliateId,
    },
  });

  const { email, phone, role } = watch();
  const { validateHasValidPhone, validateCreateParameter, validateHasName, validateHasEmail, validateHasValidEmail, validateRoleChangeToOwner } = useValidateProjectUserInputs(getValues);

  const onSave = () => {
    if (!validateHasName()) {
      setError("name", { type: "validate", message: t("dashboard.users.create.nameRequiredError") });
    }

    const emailsExludingCurrentUser = userEmails.filter((e) => e !== user.email);
    if (emailsExludingCurrentUser.includes(email)) {
      setError("email", { type: "custom", message: "Der er allerede oprettet en bruger med denne email adresse." });
      return;
    }

    if (!validateHasEmail()) {
      setError("email", { type: "custom", message: t("dashboard.users.create.emailRequiredError") });
    } else if (!validateHasValidEmail()) {
      setError("email", { type: "custom", message: t("dashboard.users.create.emailInvalidError") });
    }

    if (!validateHasValidPhone(phone ?? "")) {
      setError("phone", { type: "validate", message: t("dashboard.users.create.phoneInvalidError") });
      return;
    }

    // this check ensures that we do not proceed if an input field is invalid
    if (!validateCreateParameter()) {
      return;
    }

    if (user.status === "Accepted") {
      setValue("email", undefined);
    }

    // Sets phone to empty if there are no spaces (only +XX) or no numbers after the first space
    if (phone && (phone.indexOf(" ") === -1 || phone.substring(phone.indexOf(" ") + 1) === "")) {
      setValue("phone", "");
    }

    if (!validateRoleChangeToOwner(role) && !ownerRoleChangeDismissed) {
      // reset email so validation above passes
      setValue("email", user?.email ?? undefined);

      setError("role", { type: "validate" });
      return;
    }

    onSubmit(getValues());
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          height: screenSize === ScreenSizeEnum.Mobile ? "100%" : "auto", // mobile is ignored
          margin: screenSize === ScreenSizeEnum.Mobile ? "32px 7px" : "32px",
          width: screenSize === ScreenSizeEnum.Mobile ? "100%" : "calc(100´%-64px)",
        },
      }}
      open={props.isOpen}
    >
      <DialogTitle component="div" textAlign={"center"} p={0}>
        <Typography variant="h5" color="primary.dark">
          {t("dashboard.users.edit.title")}
        </Typography>
        <IconButton onClick={props.onClose} sx={{ position: "absolute", top: 0, right: 0 }}>
          <CloseIcon />
        </IconButton>
        <Divider sx={{ marginTop: 2 }} />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <ProjectUserName register={register} fieldError={errors.name} />
          {user.status !== "Accepted" && (
            <Box>
              <ProjectUserEmail register={register} fieldError={errors.email} />
            </Box>
          )}
          {isOwner && user.role !== "Owner" && (
            <ProjectUserRoleField
              fieldError={errors.role}
              role={mapUserRoleToProjectUserRole(user.role)}
              onOwnerConfirm={() => {
                clearErrors("role");
                setOwnerRoleChangeDismissed(true);
              }}
              control={control}
              userIsAssigned={user.status === "Accepted"}
            />
          )}
          <ProjectUserAdr register={register} />
          <ProjectUserPhoneNumber register={register} fieldError={errors.phone} value={getValues().phone} onChange={(value) => setValue("phone", value, { shouldDirty: true })} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={props.onClose}>
          {t("common.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSave} disabled={!isDirty || (getValues().role === "Owner" && !ownerRoleChangeDismissed)}>
          {t("common.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
