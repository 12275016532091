import { Fragment, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ExtraWorkAgreementResponse } from "api/generatedApi";
import { useAbortDialog } from "components/shared/alert/abort/hook/use-abort";
import DialogTitleStyled from "components/shared/dialog/dialog-title-styled";

import { FormDialogProps } from "shared/dialog/types";
import { ScreenSizeEnum, useScreenSize } from "shared/use-screen-size";
import { ExtraWorkAgreementFormData, useValidateExtraWorkAgreementInput } from "./hooks/use-input-validation";
import { EditExtraWorkAgreementDescription } from "./inputs/description";
import { EditExtraWorkAgreementName } from "./inputs/name";
import { EditExtraWorkAgreementNumber } from "./inputs/number";
import { EditExtraWorkAgreementSelectType } from "./inputs/select-type";
import { EditExtraWorkAgreementTimeOrPayment } from "./inputs/time-or-payment";
import { useKeypressEnter } from "components/shared/keypress-enter/use-keypress-enter";
import { EditExtraWorkAgreementInternalNumber } from "./inputs/internal-number";
import { EditExtraWorkAgreementStatus } from "./inputs/agreement-status";
import { EditExtraWorkAgreementIsPrivate } from "./inputs/is-private";
import { ExtraWorkAgreementHistoryTable } from "./history/history-table";
import { useFeatures } from "utils/hooks/use-features";

interface Props extends FormDialogProps<ExtraWorkAgreementFormData> {
  agreement?: ExtraWorkAgreementResponse;
  allowChanges: boolean;
}

export const UpdateExtraWorkAgreementDialog = (props: Props) => {
  const { agreement, allowChanges, onClose } = props;
  // deep copy agreement
  const oldAgreeement: ExtraWorkAgreementResponse | undefined = agreement ? JSON.parse(JSON.stringify(agreement)) : undefined;
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatures()
  const { screenSize } = useScreenSize();
  const { hasValidateInputs, hasDirtyInputs, isLockedBasedOnState, isStateLockedBasedOnState } = useValidateExtraWorkAgreementInput();
  const [isInputValid, setIsInputValid] = useState(false);
  const [mayNotEdit, setMayNotEdit] = useState(false);
  const [mayNotChangeState, setMayNotChangeState] = useState(false);
  const [showHistoryView, setShowHistoryView] = useState(false);
  const nonFunction = () => { };
  const openAbortDialog = useAbortDialog({ closeDialog: onClose ?? nonFunction });

  const { register, setValue, getValues, handleSubmit, control, watch } = useForm<ExtraWorkAgreementFormData>({
    mode: "all",
    defaultValues: {
      extraWorkAgreementType: agreement ? agreement.extraWorkAgreementType : "CompanyHours",
      workTime: agreement ? agreement.workTime : { hours: 0, minutes: 0 },
      paymentDkr: agreement?.paymentDkr,
      approvalStatus: agreement?.approvalStatus ?? "UnderDevelopment",
    },
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        name === "extraWorkAgreementNumber" ||
        name === "name" ||
        name === "description" ||
        name === "extraWorkAgreementType" ||
        name === "paymentDkr" ||
        name === "workTime" ||
        name === "workTime.hours" ||
        name === "workTime.minutes" ||
        name === "approvalStatus"
      ) {
        const isValidated = hasValidateInputs(getValues);
        const isDirty = hasDirtyInputs(getValues, oldAgreeement);
        const isApproved = isLockedBasedOnState(getValues);
        const isStateLocked = isStateLockedBasedOnState(getValues);
        setMayNotEdit(isApproved);
        setMayNotChangeState(isStateLocked);
        setIsInputValid((isValidated || !isApproved) && isDirty);
      }
    });



    return () => subscription.unsubscribe();
  }, [watch, getValues, hasValidateInputs, oldAgreeement, hasDirtyInputs, setIsInputValid, setMayNotEdit]);

  useEffect(() => {
    const isApproved = isLockedBasedOnState(getValues);
    setMayNotEdit(isApproved);
  }, [getValues]);

  const onSubmit: SubmitHandler<ExtraWorkAgreementFormData> = (data) => {
    props.onSubmit(data);
  };

  const close = () => {
    if (hasDirtyInputs(getValues, oldAgreeement)) {
      openAbortDialog();
      return;
    }
    if (onClose) {
      onClose();
    }
  };

  const onSubmitHandler = () => {
    if (isInputValid) {
      handleSubmit(onSubmit(getValues()));
    }
  };

  useKeypressEnter(onSubmitHandler);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          margin: screenSize === ScreenSizeEnum.Mobile ? "32px 7px" : "32px",
          width: screenSize === ScreenSizeEnum.Mobile ? "100%" : "calc(100´%-64px)",
        },
      }}
      open={props.isOpen}
    >
      <DialogTitleStyled title={allowChanges && !mayNotEdit ? t("dashboard.extraWorkAgreements.update.title") : t("dashboard.extraWorkAgreements.update.disabledTitle")} onClose={onClose} isOpen={props.isOpen} handleIconClose={allowChanges ? close : onClose} />
      <DialogContent>
        {showHistoryView ? (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <ExtraWorkAgreementHistoryTable auditLogEntries={agreement?.logEntries ?? []}></ExtraWorkAgreementHistoryTable>
          </Box>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <EditExtraWorkAgreementInternalNumber agreement={agreement} />
            <EditExtraWorkAgreementNumber register={register} agreement={agreement} disabled={(!allowChanges) || mayNotEdit} />
            <EditExtraWorkAgreementName register={register} agreement={props.agreement} disabled={(!allowChanges) || mayNotEdit} />
            <EditExtraWorkAgreementDescription register={register} agreement={props.agreement} disabled={(!allowChanges) || mayNotEdit} />
            <EditExtraWorkAgreementSelectType control={control} agreement={props.agreement} disabled={(!allowChanges) || mayNotEdit} />
            <EditExtraWorkAgreementTimeOrPayment watch={watch} getValue={getValues} setValue={setValue} register={register} agreement={props.agreement} disabled={(!allowChanges) || mayNotEdit} />
            <EditExtraWorkAgreementStatus register={register} agreement={props.agreement} disabled={(!allowChanges) || mayNotChangeState} getValue={getValues} />
            {isFeatureEnabled("ExtraWorkAgreements_IsPrivateFlag") && (
              <EditExtraWorkAgreementIsPrivate register={register} agreement={agreement} disabled={true} ></EditExtraWorkAgreementIsPrivate>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>

        {showHistoryView ? (
          <Button variant="outlined" onClick={() => { setShowHistoryView(false) }}>
            {t("common.back")}
          </Button>
        ) : (
          <>
            <Button variant="outlined" onClick={() => { setShowHistoryView(true) }}>
              {t("common.viewHistory")}
            </Button>
            {allowChanges ? (
              <Fragment>
                <Button variant="outlined" onClick={close}>
                  {t("common.cancel")}
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)} disabled={!isInputValid}>
                  {t("common.save")}
                </Button>
              </Fragment>
            ) : (
              <Button variant="outlined" onClick={onClose ?? onClose}>
                {t("common.close")}
              </Button>
            )}
          </>
        )}

      </DialogActions>
    </Dialog>
  );
};
