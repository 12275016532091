import { TableCell, TableRow, Typography } from "@mui/material"
import { ExtraWorkAggreementApprovalStatus, ExtraWorkAgreementAuditLogEntry } from "api/generatedApi";
import { formatDate, formatTimestampToDate } from "utils/formats";
import { useExtraWorkAgreementFormatter } from "../../hooks/format";

interface Props {
  row: ExtraWorkAgreementAuditLogEntry;
}

export const ExtraWorkAgreementHistoryTableRow = (props: Props) => {
  const { getAuditLogStatusFormatted, getStatusFormatted } = useExtraWorkAgreementFormatter();
  return (
    <TableRow>
      <TableCell>{formatDate(formatTimestampToDate(props.row.timestamp), true)}</TableCell>
      <TableCell>{props.row.userName}</TableCell>
      <TableCell>
        {getAuditLogStatusFormatted(props.row.eventType || "")}
        {props.row.eventType === 'Update Status' && (
          <Typography variant="body2">
            {getStatusFormatted(props.row.description as ExtraWorkAggreementApprovalStatus)}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  )
}