import { MaterialMountingResponse } from "api/generatedApi";

export const stepWorkitemType = 0;
export const stepMaterialAmount = 1;
export const stepMaterialMountingCode = 2;
export const stepMaterialSupplementOperations = 3;
export const stepMaterialSupplements = 4;
export const stepMaterialDates = 5;
export const stepMaterialCalculatedWorkItemPreview = 6;

export const stepOperationAmount = 1;
export const stepOperationSupplements = 2;
export const stepOperationDates = 3;
export const stepOperationCalculatedWorkItemPreview = 4;

export const stepShowInfo = 10;
export const lastStep = stepShowInfo;

export const noMountingCodeSelected: MaterialMountingResponse = { mountingCode: -1, operationTimeMilliseconds: 0 };
