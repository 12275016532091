import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App, NotFound } from "App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { makePath } from "api/path";
import { AuthenticationResult, BrowserCacheLocation, Configuration, EventMessage, EventType, PopupRequest, PublicClientApplication } from "@azure/msal-browser";
import { BroadcastChannel } from "broadcast-channel";
import { ConfigResponse } from "api/generatedApi";

// Overpass font
import "./assets/fonts/Overpass/Overpass.css";

if (process.env.NODE_ENV === "development" && localStorage.getItem("MockNetwork") === "true") {
  const { worker } = require("./mocks/browser");
  worker.start();
}

let configuration: Configuration | null = null;
let fullConfiguration: ConfigResponse | null = null;
let pca: PublicClientApplication | null = null;
let isCachedConfig: boolean = false;

async function loadConfig(): Promise<ConfigResponse | undefined> {
  try {
    const apiConfig = await fetch(makePath("/api/config")).then((res) => res.json());
    isCachedConfig = false;
    return apiConfig;
  } catch {
    isCachedConfig = true;
    return JSON.parse(localStorage.getItem("apiConfig") as string) as ConfigResponse;
  }
}

// redirectUri does not support query parameters, as you cannot register on the App registration used by B2C
// https://origin.com/link => this is fine
// https://origin.com/link?value=somevalue => this is no good
export const loginRequest = (domainHint?: string): PopupRequest => {
  if (configuration) {
    return {
      scopes: [configuration.auth.clientId + " openid"],
      domainHint,
    };
  }
  throw Error("Config not loaded");
};

export const getPCA = (): PublicClientApplication => {
  if (pca) {
    return pca;
  }
  throw Error("Config not loaded");
};

export const getConfig = () => {
  if (fullConfiguration) {
    return fullConfiguration;
  }
  throw Error("Config not loaded");
};

loadConfig().then((config) => {
  if (config) {
    localStorage.setItem("apiConfig", JSON.stringify(config));

    fullConfiguration = config;
    configuration = {
      auth: {
        clientId: config.azureAdB2C?.clientId ? config.azureAdB2C.clientId : "",
        authority: config.azureAdB2C?.authority ? config.azureAdB2C.authority : "",
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        knownAuthorities: config.azureAdB2C?.knownAuthority ? [config.azureAdB2C.knownAuthority] : [],
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Shared across browser tabs and windows
      },
    };
    pca = new PublicClientApplication(configuration);

    const msalInstance = getPCA();
    const accounts = pca.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
      }
    });

    const root = createRoot(document.getElementById("root")!);
    root.render(<StrictMode>{isCachedConfig ? <NotFound /> : <App />}</StrictMode>);
  }
});

// Multi tabs logout
export const logoutChannel = new BroadcastChannel("logout");

export const logout = () => {
  const msalInstance = getPCA();
  logoutChannel.postMessage("logout");
  msalInstance.logoutRedirect();
};

export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    logout();
    logoutChannel.close();
  };
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();
// serviceWorkerRegistration.register({
//   onUpdate: (e) => {
//     const {waiting: { postMessage = null} = {} as any, update } = e || {};
//     if (postMessage) {
//       postMessage({ type: 'SKIP_WAITING' });
//     }
//     alert("New opdate er her");
//     update().then(() => {
//       window.location.reload();
//     })
//   }
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
