import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { ExtraWorkAgreementResponse } from "api/generatedApi";
import { InputContainer } from "shared/styles/input-container-style";

interface Props {
  agreement?: ExtraWorkAgreementResponse;
}

export const EditExtraWorkAgreementInternalNumber = (props: Props) => {
  const { t } = useTranslation();

  return (
    <InputContainer>
      <Typography variant="caption" color={"primary.main"}>
        {t("dashboard.extraWorkAgreements.table.internalId")}
      </Typography>
      <Typography variant="body1">{props.agreement?.extraWorkAgreementInternalNumber ?? 0}</Typography>

    </InputContainer>
  );
};
