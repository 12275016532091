import { useTranslation } from "react-i18next";
import { Control, Controller, FieldError } from "react-hook-form";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { InputContainer } from "../../../../../../../shared/styles/input-container-style";
import { CreateProjectUserFormData } from "./project-user-form-data";
import { Alert, Box, Button } from "@mui/material";
import { useState } from "react";
import { UserRole } from "api/generatedApi";

interface Props {
  control: Control<CreateProjectUserFormData>;
  role?: UserRole;
  fieldError?: FieldError | undefined;
  userIsAssigned?: boolean;
  onOwnerConfirm?: () => void;
}

export const ProjectUserRoleField = (props: Props) => {
  const { role, userIsAssigned, fieldError, onOwnerConfirm } = props;
  const { t } = useTranslation();

  const [ownerSelected, setOwnerSelected] = useState<boolean>();

  return (
    <Box>
      <InputContainer>
        <Typography variant="caption" color={"primary.main"}>
          {t("dashboard.users.create.captionRole")}
        </Typography>
        <FormControl error={!!fieldError?.message} component="fieldset">
          <Controller
            rules={{ required: true }}
            control={props.control}
            name="role"
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(event) => {
                  setOwnerSelected(event?.target?.value === "Owner");
                  field.onChange(event);
                }}
              >
                <FormControlLabel data-testid="create-project-users-role-participant" value={"ProjectParticipant"} control={<Radio />} label={t("dashboard.users.roles.participant")} />
                <FormControlLabel data-testid="create-project-users-role-manager" value={"ProjectManager"} control={<Radio />} label={t("dashboard.users.roles.manager")} />
                <FormControlLabel data-testid="create-project-users-role-inactive" value={"ProjectInactiveParticipant"} control={<Radio />} label={t("dashboard.users.roles.inactive")} />
                <FormControlLabel data-testid="create-project-users-role-owner" value={"Owner"} disabled={role !== "ProjectManager" || !userIsAssigned} control={<Radio />} label={t("dashboard.users.roles.owner")} />
              </RadioGroup>
            )}
          />
        </FormControl>
      </InputContainer>
      {ownerSelected && (
        <Alert
          severity="error"
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                setOwnerSelected(false);
                onOwnerConfirm!();
              }}
            >
              {t("common.confirm")}
            </Button>
          }
        >
          {t("dashboard.users.changeOwnerWarning")}
        </Alert>
      )}
    </Box>
  );
};
