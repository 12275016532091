import { usePostApiProjectsByProjectIdInvitationsMutation } from "api/generatedApi";
import { useTranslation } from "react-i18next";
import { useDialog } from "shared/dialog/use-dialog";
import { ResultDialogType, useMutation } from "utils/hooks/use-mutation";
import { ConfirmInviteDialog } from "../confirm-invite-dialog";

interface Props {
  email: string;
  projectAffiliateId: string;
  projectId: string;
}

export function useConfirmInviteDialog(props: Props) {
  const { email, projectId, projectAffiliateId } = props;
  const { t } = useTranslation();
  const [openConfirmInvite, closeConfirmInvite] = useDialog(ConfirmInviteDialog);
  const [createInvitation] = usePostApiProjectsByProjectIdInvitationsMutation();

  const wrapMutation = useMutation({
    onSuccess: closeConfirmInvite,
    successProps: {
      description: t("dashboard.users.invite.success"),
    },
    errorProps: {
      description: t("dashboard.users.invite.error"),
    },
    resultDialogType: ResultDialogType.Toast,
  });

  const handleSubmit = () => {
    wrapMutation(
      createInvitation({
        projectId: projectId,
        createInvitationRequest: { projectAffiliateId },
      })
    );
  };

  const handleClick = () => {
    openConfirmInvite({
      onSubmit: handleSubmit,
      email,
    });
  };

  return handleClick;
}
