import { Box, SxProps, Tooltip } from "@mui/material";
import styled from "@mui/system/styled";
import { costumPalette } from "theme/palette";

const Dot = styled(Box)`
  height: 10px;
  width: 10px;
  background-color: ${costumPalette.offlineBlue};
  border-radius: 50%;
  display: inline-block;
`;

interface ItemDotProps {
  title?: string;
  sx?: SxProps;
}

export const ItemDot = (props: ItemDotProps) => {
  const { title, sx } = props;
  if (title) {
    return (
      <Tooltip title={title}>
        <Dot sx={sx} />
      </Tooltip>
    );
  }
  return <Dot sx={sx} />;
};
