import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { useGetApiCatalogMaterialsByMaterialIdQuery, type GetProjectResponse, type ProjectFolderResponse, type WorkItemResponse, useGetApiCatalogOperationsByOperationIdQuery, ProjectSpecificOperationAndFavoriteResponse } from "api/generatedApi";
import type { DialogBaseProps } from "shared/dialog/types";
import { ScreenSizeEnum, useScreenSize } from "shared/use-screen-size";
import { WorkItemSummary } from "../create/create-work-item-dialog/step-info/work-item-summary";
import { useEditWorkItem } from "../edit/edit-work-item/use-edit-work-item";
import { DividerStyled } from "./show-work-item-info-dialog-styles";
import { useWorkItemRestrictions } from "shared/user-restrictions/use-workitems-restrictions";
import { useToast } from "shared/toast/hooks/use-toast";
import { useOfflineStorage } from "utils/offline-storage/use-offline-storage";

interface Props extends DialogBaseProps {
  project: GetProjectResponse;
  folder: ProjectFolderResponse;
  workItem: WorkItemResponse;
}

export function ShowWorkItemInfoDialog(props: Props) {
  const { project, folder, workItem } = props;
  const { t } = useTranslation();
  const { screenSize } = useScreenSize();
  const toast = useToast();
  const { canEditWorkitem } = useWorkItemRestrictions(project);
  const { getCurrentProjectId, getOfflineFavorites } = useOfflineStorage();

  const [skipMaterial, setSkipMaterial] = React.useState(true);
  const [skipOperation, setSkipOperation] = React.useState(true);
  const { data: materialData, error: materialError } = useGetApiCatalogMaterialsByMaterialIdQuery({ materialId: workItem.workItemMaterial?.catalogMaterialId ?? "" }, { skip: skipMaterial });
  const { data: operationData, error: operationError } = useGetApiCatalogOperationsByOperationIdQuery({ operationId: workItem.workItemOperation?.catalogOperationId ?? "" }, { skip: skipOperation });
  const [projectSpecificOperationsData, setProjectSpecificOperationsData] = React.useState<ProjectSpecificOperationAndFavoriteResponse>();
  const [projectSpecificOperationsError, setProjectSpecificOperationsError] = React.useState(false);

  const openEditDialog = useEditWorkItem({ project, folder, workItem, materialData, operationData, projectSpecificOperationsData });

  const [updateDisabled, setUpdateDisabled] = React.useState(false);

  const workItemExists = () => {
    if (workItem.workItemType === "Material") {
      setSkipMaterial(false);
    } else if (workItem.workItemType === "Operation") {
      setSkipOperation(false);
    } else if (workItem.workItemType === "ProjectSpecificOperation") {
      const favorites = getOfflineFavorites(getCurrentProjectId());
      if (favorites.some((favorite) => favorite.catalogId === workItem?.workItemProjectSpecificOperationResponse?.projectSpecificOperationId)) {
        setProjectSpecificOperationsData(favorites.find((favorite) => favorite.catalogId === workItem?.workItemProjectSpecificOperationResponse?.projectSpecificOperationId));
      } else {
        setProjectSpecificOperationsError(true);
      }
    }
    setUpdateDisabled(true);
  };

  React.useEffect(() => {
    if (materialData || operationData || projectSpecificOperationsData) {
      if (props.onClose) {
        props.onClose();
      }
      openEditDialog();
    }
  }, [materialData, openEditDialog, operationData, projectSpecificOperationsData, props, t, toast]);

  React.useEffect(() => {
    if ((materialError && "status" in materialError && materialError.status === 404) || (operationError && "status" in operationError && operationError.status === 404) || projectSpecificOperationsError) {
      toast.error(t("content.measurements.edit.errors.workItemNotFound"));
    }
  }, [materialError, operationError, projectSpecificOperationsError, t, toast]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          height: screenSize === ScreenSizeEnum.Mobile ? "100%" : "750px", // mobile is ignored
          margin: screenSize === ScreenSizeEnum.Mobile ? "32px 7px" : "32px",
          width: screenSize === ScreenSizeEnum.Mobile ? "100%" : "calc(100%-64px)",
        },
      }}
      open={props.isOpen}
    >
      <DialogTitle component="div" textAlign={"center"}>
        <Typography variant="h5" color="primary.dark">
          {t("content.measurements.info.headerView")}
        </Typography>
        <IconButton onClick={props.onClose} sx={{ position: "absolute", top: 0, right: 0 }}>
          <CloseIcon />
        </IconButton>
        <DividerStyled sx={{ borderColor: "grey.100" }} />
      </DialogTitle>
      <DialogContent data-testid="is-the-the-container" sx={{ padding: 0 }}>
        {!workItem && (
          <Box sx={{ mt: 5, textAlign: "center" }}>
            <CircularProgress size={100} />
          </Box>
        )}
        {workItem && <WorkItemSummary workItem={workItem} />}
      </DialogContent>
      <DialogActions>
        {canEditWorkitem(folder) && (
          <Button data-testid="workitem.info-action-btn-edit" variant="contained" disabled={updateDisabled} onClick={workItemExists}>
            {t("common.update")}
          </Button>
        )}
        <Button data-testid="workitem.info-action-btn-next" variant="contained" onClick={props.onClose}>
          {t("common.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
